import React, { Component } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

class Partial extends Component {
    constructor(props){
        super(props);
    }
    state = {
        displayPreview: "none"
    }
    showPreview = () => {
        this.setState({
            displayPreview: 'block'
        });
    }
    unShowPreview = () => {
        this.setState({
            displayPreview: 'none'
        });
    }
    copyName = () => {
        let copyText = this.props.name;
        copyText.select();
        document.execCommand("copy");
    }
    render() {
        let displayName = "";
        let imgName = this.props.name.split('.')[0];
        let src = "./img/previewPartials/"+this.props.type+"/"+imgName+".jpg";
        let other = this.props.name.split('.')[1];
        if(other !== "html") {
            displayName = "none";
        } else {
            displayName = "block";
        }
        return (
                <div style={{display:displayName}} className="itemPartial" onMouseEnter={this.showPreview} onMouseLeave={this.unShowPreview}>
                    
                    <CopyToClipboard onCopy={this.onCopy} text={`<%include ${this.props.name}%>`}>
                        <span>{this.props.name}</span>
                    </CopyToClipboard>
                    <div style={{display:this.state.displayPreview}} className="containerPreviewPartial">
                        <img src={src}></img>
                    </div>
                    <CopyToClipboard onCopy={this.onCopy} text={`<%include ${this.props.name}%>`}>
                        <p>Copier</p>
                    </CopyToClipboard>
                    
                </div>
        )
    }
}

export default Partial