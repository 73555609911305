import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class Register extends Component {
    state = {
        username: '',
        email: '',
        password: '',
        couleur: '#555555',
        errors: {}
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    activeColor = e => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            console.log(this.state.couleur);
            
        })
        
    }
    handleSubmit = e => {
        e.preventDefault();
        const newUser = {
            username: this.state.username,
            email: this.state.email,
            password: this.state.password,
            color: this.state.couleur
        };
        axios
            .post('/api/users/register', newUser)
            .then(res => {
                cookies.set("userId", res.data._id)
                this.props.history.push('/', { _id: res.data._id })
            })
            .catch(err =>
                this.setState({
                    errors: err.response
                })    
            );
    };

    render() {
        const { username, email, password, errors } = this.state;
        return (
            <React.Fragment>
                <div className="containerLogin">
                    <div className="containerContent">
                        <h1>Emailing Manager</h1>
                        <div className="containerForm">
                            <div className="leftForm">
                                <img src="img/imgLogin.png"></img>
                            </div>
                            <div className="rightForm">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="containerChamps">
                                    <div className="">
                                        <input 
                                            type="text" 
                                            name="username"
                                            value={username}
                                            onChange={this.handleChange}
                                            className={
                                                errors.type === "username" 
                                                ? 'is-invalid form-control' 
                                                : 'form-control'
                                            }
                                            placeholder="Enter your username"
                                        />
                                        <span className="invalid-feedback">{errors.msg}</span>
                                        </div>
                                        <div className="mt10">
                                            <input 
                                                type="email" 
                                                name="email"
                                                value={email}
                                                onChange={this.handleChange}
                                                className={
                                                    errors.type === "email" 
                                                    ? 'is-invalid form-control' 
                                                    : 'form-control'
                                                }
                                                placeholder="Enter your email"
                                            />
                                            <span className="invalid-feedback">{errors.msg}</span>
                                        </div>
                                        <div className="mt10">
                                            <input 
                                                type="password" 
                                                name="password"
                                                value={password}
                                                onChange={this.handleChange}
                                                className={
                                                    errors.type === "password" 
                                                    ? 'is-invalid form-control' 
                                                    : 'form-control'
                                                }
                                                placeholder="Enter your password"
                                            />
                                            <span className="invalid-feedback">{errors.msg}</span>
                                        </div>
                                        <div className="mt10">
                                            <p>Couleur sélectionnée : {this.state.couleur}</p>
                                            <p>(Cliquez sur le bouton si dessous pour changer)</p>
                                            <input 
                                                type="color" 
                                                name="couleur"
                                                value={this.state.couleur}
                                                onChange={this.activeColor}
                                                placeholder="Enter your color"
                                            />
                                        </div>
                                    </div>
                                    
                                    <input
                                        type="submit"
                                        className="btn btn-primary justify-content-center d-flex w-50 connexionButton"
                                        value="Register"
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                
            </React.Fragment>
        )
    }
}

export default Register