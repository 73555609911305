import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Loader from 'react-loader-spinner';
import access from '../auth/Access.js';

const cookies = new Cookies();
const Loadering = () => <div className="containerLoading">
                            <div
                                    className="loading"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                <Loader type="ThreeDots" color="#2BAD60" />
                            </div>
                        </div>;
class Test extends Component {
    constructor(props){
        super(props);
    }
    state = {
        emailsInterne: '',
        emailsBat: '',
        subjectBat: '',
        displayInterne: 'none',
        displayBat: 'none',
        emailUser: cookies.get("email"),
        projId: cookies.get("projectId"),
        nombreBat: 0,
        displayOrangeModifier: "none",
        displayOrangeEnvoyer: "none",
        displayOrangeGenerer: "none",
        isLoading: false,
        imgOne: "img/doneIcon.png",
        imgTwo: "img/interneIcon.png",
        imgThree: "img/interneIcon.png",
        bgOne: "#33225d",
        bgTwo: "#ffffff",
        bgThree: "#ffffff",
        borderOne: 'none',
        borderTwo: 'solid 1px rgba(215, 211, 223, 0.5)',
        borderThree: 'solid 1px rgba(215, 211, 223, 0.5)',
        typoOne: "#ffffff",
        typoTwo: "#777777",
        typoThree: "#777777",
        contentInterne: "flex",
        contentOnAcid: "none",
        contentBat: "none",
        etatChoice: "interne",
        displayEnvoieMail: "none",
        displayEnvoyer: "none",
        displayEnvoieDefault: "none",
        displayEnvoieOnAcid: "none",
        ville: "",
        programme: "",
        jobNumber: "",
        promoteur: ""
    };
    cleanInterne = () => {
        this.setState({
            displayEnvoieMail: "none",
        })
    }
    cleanAccuse = () => {
        this.setState({
            displayEnvoieMail: "none",
            displayEnvoyer: "none",
        })
    }
    envoyerMail = () => {
        this.setState({
            displayEnvoieMail: "block"
        })
    }
    activeInterne = () => {
        if(this.state.etatChoice === "interne") {
            // console.log('interne');
        } else {
            this.setState({
                etatChoice: "interne",
                imgOne: "img/doneIcon.png",
                imgTwo: "img/interneIcon.png",
                imgThree: "img/interneIcon.png",
                bgOne: "#33225d",
                bgTwo: "#ffffff",
                bgThree: "#ffffff",
                borderOne: 'none',
                borderTwo: 'solid 1px rgba(215, 211, 223, 0.5)',
                borderThree: 'solid 1px rgba(215, 211, 223, 0.5)',
                typoOne: "#ffffff",
                typoTwo: "#777777",
                typoThree: "#777777",
                contentInterne: "flex",
                contentOnAcid: "none",
                contentBat: "none",
            })
        }
    }
    activeOnAcid = () => {
        if(this.state.etatChoice === "onAcid") {
            // console.log('onAcid');
        } else {
            this.setState({
                etatChoice: "onAcid",
                imgOne: "img/interneIcon.png",
                imgTwo: "img/doneIcon.png",
                imgThree: "img/interneIcon.png",
                bgOne: "#ffffff",
                bgTwo: "#33225d",
                bgThree: "#ffffff",
                borderOne: 'solid 1px rgba(215, 211, 223, 0.5)',
                borderTwo: 'none',
                borderThree: 'solid 1px rgba(215, 211, 223, 0.5)',
                typoOne: "#777777",
                typoTwo: "#ffffff",
                typoThree: "#777777",
                contentInterne: "none",
                contentOnAcid: "flex",
                contentBat: "none",
            })
        }
    }
    activeBat = () => {
        if(this.state.etatChoice === "bat") {
            // console.log('bat');
        } else {
            this.setState({
                etatChoice: "bat",
                imgOne: "img/interneIcon.png",
                imgTwo: "img/interneIcon.png",
                imgThree: "img/doneIcon.png",
                bgOne: "#ffffff",
                bgTwo: "#ffffff",
                bgThree: "#33225d",
                borderOne: 'solid 1px rgba(215, 211, 223, 0.5)',
                borderTwo: 'solid 1px rgba(215, 211, 223, 0.5)',
                borderThree: 'none',
                typoOne: "#777777",
                typoTwo: "#777777",
                typoThree: "#ffffff",
                contentInterne: "none",
                contentOnAcid: "none",
                contentBat: "flex",
            })
        }
    }
    openOnAcid = () => {
        window.open('https://www.emailonacid.com/', "_blank");
    }
    envoieMail = (e) => {
        if(this.state.etatChoice === "interne") {
            this.testInterne(e);
            this.saveContactsInterne(e);
            this.setState({
                displayEnvoieDefault: "flex",
                displayEnvoieOnAcid: "none"
            })
            // console.log('envoie interne');
        }
        if(this.state.etatChoice === "onAcid") {
            this.testOnAcid(e);
            this.setState({
                displayEnvoieDefault: "none",
                displayEnvoieOnAcid: "flex"
            })
            // console.log('test onAcid');
        }
        if(this.state.etatChoice === "bat") {
            this.sendBat(e);
            this.saveContacts(e);
            this.setState({
                displayEnvoieDefault: "flex",
                displayEnvoieOnAcid: "none"
            })
            // console.log('envoie bat');
        }
    }
    reEnvoieMail = () => {
        this.setState({
            isLoading: false,
            displayEnvoieMail: "block",
            displayEnvoyer: "none"
        })
    }
    modifierIn = () => {
        this.setState({
            displayOrangeModifier: "block"
        });
    }
    modifierOut = () => {
        this.setState({
            displayOrangeModifier: "none"
        });
    }
    envoyerIn = () => {
        this.setState({
            displayOrangeEnvoyer: 'block'
        });
    }
    envoyerOut = () => {
        this.setState({
            displayOrangeEnvoyer: 'none'
        });
    }
    genererIn = () => {
        this.setState({
            displayOrangeGenerer: 'block'
        });
    }
    genererOut = () => {
        this.setState({
            displayOrangeGenerer: 'none'
        });
    }
    dashboard = () => {
        return this.props.history.push('/dashboard')
    }
    zipper = () => {
        const content = {
            username: this.props.location.state.userCreator,
            folder: this.props.location.state.folderPath
        }; 
        return this.props.history.push('/zipFolder', { username: content.username, folderPath: content.folder })
    }
    testInterne = async e => {
        e.preventDefault();
        const content = {
            username: this.props.location.state.userCreator,
            userUpdator: cookies.get("username"),
            emailDest: cookies.get("email"),
            folder: this.props.location.state.folderPath,
            addEmail: this.state.emailsInterne
        }; 
        console.log(content.folder);
        await this.send360(content);
        await this.updateDbInterne(content);
        
    }
    updateDbInterne = async (content) =>{
        let response = await fetch(`/api/projects/${this.state.projId}`, {
            headers : {
                "Content-Type": "application/json"
            },
            method: "PATCH",
            body: JSON.stringify({
                status: "BAT interne",
                statusFictif: "BAT interne",
                userUpdator: content.userUpdator
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    testOnAcid = async e => {
        e.preventDefault();
        const content = {
            username: this.props.location.state.userCreator,
            folder: this.props.location.state.folderPath
        }; 
        await this.sendOnAcid(content);

    }
    modifier = e => {
        e.preventDefault();
        const content = {
            username: this.props.location.state.userCreator,
            folder: this.props.location.state.folderPath
        }; 
        this.redirectContent(content);
    }
    send360 = async (content) => {
        this.setState({
            isLoading: true
        })
        let response = await fetch("/api/mail/send360", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                subject: this.state.programme + ' ' + this.state.promoteur,
                username: content.username,
                addEmail: content.addEmail,
                emailDest: content.emailDest
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
            this.setState({
                isLoading: false,
                displayEnvoieMail: "none",
                displayEnvoyer: "block"
            })
        } else {
            alert("Un problème est survenu")
            this.setState({
                isLoading: false
            })
        }
    }
    sendOnAcid = async (content) => {
        this.setState({
            isLoading: true
        })
        let response = await fetch("/api/mail/sendOnAcid", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.username,
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
            // window.open('https://www.emailonacid.com/', "_blank");
            this.setState({
                isLoading: false,
                displayEnvoieMail: "none",
                displayEnvoyer: "block"
            })
        } else {
            alert("Un problème est survenu")
            this.setState({
                isLoading: false
            })
        }
    }
    sendBat = async e => {
        e.preventDefault();
        const content = {
            username: this.props.location.state.userCreator,
            folder: this.props.location.state.folderPath,
            userUpdator:  cookies.get("username"),
            emailDest: cookies.get("email"),
            addEmail: this.state.emailsBat,
            batNumero: this.state.nombreBat + 1,
            subject: this.state.subjectBat
        }; 
        if(this.state.subjectBat == '' || this.state.subjectBat == undefined){
            alert('merci de renseigner l\' objet du mail')
        } else {
            await this.bat(content);
            await this.updateDbBat(content);
        }
    }
    bat = async (content) => {
        let response = await fetch("/api/mail/sendBat", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.username,
                emailDest: content.emailDest,
                addEmail: content.addEmail,
                batNumero: content.batNumero,
                subject: content.subject
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
            
            this.setState({
                isLoading: false,
                displayEnvoieMail: "none",
                displayEnvoyer: "block"
            })
        } else {
            alert("Un problème est survenu")
            this.setState({
                isLoading: false
            })
        }
    }
    updateDbBat = async (content) =>{
        let response = await fetch(`/api/projects/${this.state.projId}`, {
            headers : {
                "Content-Type": "application/json"
            },
            method: "PATCH",
            body: JSON.stringify({
                status: "BAT client",
                statusFictif: "BAT client",
                bat: content.batNumero,
                userUpdator: content.userUpdator,
                subject: content.subject
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    redirectContent = (content) => {
        return this.props.history.push('/content', { userCreator: content.username, folderPath: content.folder, ville: this.state.ville, programme: this.state.programme, jobNumber: this.state.jobNumber, promoteur: this.state.promoteur })
    }
    archiver = async e => {
        e.preventDefault();
        const content = {
            username: this.props.location.state.userCreator,
            userUpdator:  cookies.get("username"),
            folder: this.props.location.state.folderPath,
            projId: cookies.get("projectId"),
        }; 
        await this.updateDb(content);
        this.redirectDashboard();
        
    }
    updateDb = async (content) =>{
        let response = await fetch(`/api/projects/${content.projId}`, {
            headers : {
                "Content-Type": "application/json"
            },
            method: "PATCH",
            body: JSON.stringify({
                status: "Archive",
                statusFictif: "Archive",
                userUpdator: content.userUpdator
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    saveContacts = async e => {
        e.preventDefault();
        const content = {
            username: this.props.location.state.userCreator,
            userUpdator:  cookies.get("username"),
            folder: this.props.location.state.folderPath,
            projId: cookies.get("projectId"),
        }; 
        await this.updateDbContacts(content);
    }
    saveContactsInterne = async e => {
        e.preventDefault();
        const content = {
            username: this.props.location.state.userCreator,
            userUpdator:  cookies.get("username"),
            folder: this.props.location.state.folderPath,
            projId: cookies.get("projectId"),
        }; 
        await this.updateDbContactsInterne(content);
    }
    updateDbContacts = async (content) =>{
        let response = await fetch(`/api/projects/${content.projId}`, {
            headers : {
                "Content-Type": "application/json"
            },
            method: "PATCH",
            body: JSON.stringify({
                mailBat: this.state.emailsBat
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            console.log('Enregistré avec succès : ' , result);
            // alert('Enregistré avec succès : ' , result)
        }
    }
    updateDbContactsInterne = async (content) =>{
        let response = await fetch(`/api/projects/${content.projId}`, {
            headers : {
                "Content-Type": "application/json"
            },
            method: "PATCH",
            body: JSON.stringify({
                mailInterne: this.state.emailsInterne
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            console.log('Enregistré avec succès : ' , result);
            // alert('Enregistré avec succès : ' , result)
        }
    }
    redirectDashboard = () => {
        return this.props.history.push('/dashboard')
    }
    closeInterne = () => {
        this.setState({
            displayInterne: 'none'
        });
    }
    openInterne = () => {
        this.setState({
            displayInterne: 'block'
        });
    }
    closeBat = () => {
        this.setState({
            displayBat: 'none'
        });
    }
    openBat = () => {
        this.setState({
            displayBat: 'block'
        });
    }
    handleChangeTestInterne = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    handleChangeBat = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    async componentDidMount(){
        this.setState({
            isLoading: true,
        })
        console.log(
            'userCreator', this.props.location.state.userCreator,
            'folderPath', this.props.location.state.folderPath
        );
        if ( localStorage.getItem('usertoken') == null ) {
            return this.props.history.push('/')
        } else {   
            let response = await fetch(`/api/projects/${this.state.projId}`);
            let result = await response.json();
            // console.log(result);
            this.setState({
                nombreBat: result.data.bat,
                ville: result.data.ville,
                programme: result.data.campagne,
                jobNumber: result.data.job,
                promoteur: result.data.promoteur,
                subjectBat: result.data.subject,
                emailsBat: result.data.mailBat,
                emailsInterne: result.data.mailInterne
            })
            let objCookies = cookies.getAll();
            let allCookies = Object.keys(objCookies);
            /* window.addEventListener("beforeunload", function (e) {
                e.preventDefault();
                for ( let i in allCookies ) {
                    // console.log(allCookies[i]);
                    cookies.remove(allCookies[i])
                }
                localStorage.removeItem('usertoken')
                access.logout();
            });  */
        }
        const content = {
            username: this.props.location.state.userCreator,
            folder: this.props.location.state.folderPath, 
        };
        await this.deploy(content);
        // console.log(content);
        this.setState({
            isLoading: false,
        })
        
    }
    deploy = async (content) => {
        // console.log(content);
        let response = await fetch("/api/gulpRoute/deployOnFtp", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.username,
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    render() {
        return (
            <div className="containerTest">
                {(this.state.isLoading) ? <Loadering /> : null}
                <div className="testInterneContainer" style={{display:this.state.displayInterne}}>
                    <h3>Vous pouvez ajouter des emails de reception</h3>
                    <p>( L'adresse de reception par default est : {this.state.emailUser} )</p>
                    <p>Vous pouvez ajouter ici d'autres adresses de reception en les séparant pas une virgule</p>
                    <form onSubmit={this.testInterne}>
                        <div className="form-group">
                            <input 
                                className="champsTest"
                                type="text"
                                name="emailsInterne"
                                value={this.state.emailsInterne}
                                onChange={this.handleChangeTestInterne}
                                placeholder="Ajouter des emails"
                            />
                        </div>
                        <div className="containerButtons">
                            <input
                                onClick={this.closeInterne}
                                type="submit"
                                className="btn btn-primary justify-content-center d-flex w-100 mr-5"
                                value="Envoyer"
                            />
                            <input
                                onClick={this.closeInterne}
                                type="button"
                                className="btn btn-primary justify-content-center d-flex w-100 ml-5"
                                value="Fermer"
                            />
                        </div>
                        
                    </form>
                </div>
                <div className="batContainer" style={{display:this.state.displayBat}}>
                    <h3>Vous pouvez ajouter des emails de reception</h3>
                    <p>( L'adresse de reception par default est : {this.state.emailUser} )</p>
                    <p>Vous pouvez ajouter ici d'autres adresses de reception en les séparant pas une virgule</p>
                    <p>nombre de BAT déjà envoyés : {this.state.nombreBat}</p>
                    <form onSubmit={this.sendBat}>
                        <div className="form-group">
                            <input 
                                className="champsTest"
                                type="text"
                                name="emailsBat"
                                value={this.state.emailsBat}
                                onChange={this.handleChangeBat}
                                placeholder="Ajouter des emails"
                            />
                        </div>
                        <div className="containerButtons">
                            <input
                                onClick={this.closeBat}
                                type="submit"
                                className="btn btn-primary justify-content-center d-flex w-100 mr-5"
                                value="Envoyer"
                            />
                            <input
                                onClick={this.closeBat}
                                type="button"
                                className="btn btn-primary justify-content-center d-flex w-100 ml-5"
                                value="Fermer"
                            />
                        </div>
                        
                    </form>
                </div>
                <div className="bgTest">
                    <div className="containerOptions">
                        <h1>Vous souhaitez</h1>
                        <div className="containerChoices">
                            <div className="choice" onClick={this.modifier}>
                                <img src="img/big-ic-enveloppe.png"></img>
                                <div className="text">
                                    <h4>Modifier</h4>
                                    <p>votre emailing</p>
                                </div>
                                <div className="bgOrange"></div>
                            </div>
                            <div className="choice" onClick={this.envoyerMail}>
                                <img src="img/big-ic-send.png"></img>
                                <div className="text">
                                    <h4>Envoyer</h4>
                                    <p>votre emailing</p>
                                </div>
                                <div className="bgOrange"></div>
                            </div>
                            <div className="choice" onClick={this.zipper}>
                                <img src="img/big-ic-gear.png"></img>
                                <div className="text">
                                    <h4>Générer</h4>
                                    <p>vos kits emailing</p>
                                </div>
                                <div className="bgOrange"></div>
                            </div>
                        </div>
                        <div className="backToDash" onClick={this.dashboard}>
                            <img src="img/backWhite.png"></img>
                            <p>Retourner au Dashboard</p>
                        </div>
                    </div>
                </div>
                <div className="envoieMail"  style={{display:this.state.displayEnvoieMail}}>
                    <div className="bgEnvoieMail">
                        <div className="containerEnvoieMail">
                            <div className="headerEnvoieMail">
                                <img src="img/envoieIcon.png"></img>
                                <h3>Envoyer un emailing</h3>
                            </div>
                            <div className="containerForm">
                                <div className="leftForm">
                                    <div className="title">
                                        <div className="circle">
                                            <h4>1</h4>
                                        </div>
                                        <p>Type d’envoi</p>
                                    </div>
                                    <div className="list">
                                        <div className="item" style={{backgroundColor:this.state.bgOne, border:this.state.borderOne}} onClick={this.activeInterne}>
                                            <img src={this.state.imgOne}></img>
                                            <p  style={{color:this.state.typoOne}}>Test interne</p>
                                            <div className="emptyDiv"></div>
                                        </div>
                                        <div className="item" style={{backgroundColor:this.state.bgTwo, border:this.state.borderTwo}} onClick={this.activeOnAcid}>
                                            <img src={this.state.imgTwo}></img>
                                            <p  style={{color:this.state.typoTwo}}>EmailOnAcid</p>
                                            <div className="emptyDiv"></div>
                                        </div>
                                        <div className="item" style={{backgroundColor:this.state.bgThree, border:this.state.borderThree}} onClick={this.activeBat}>
                                            <img src={this.state.imgThree}></img>
                                            <p  style={{color:this.state.typoThree}}>BAT</p>
                                            <div className="emptyDiv"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rightForm">
                                    <div className="title">
                                        <div className="circle">
                                            <h4>2</h4>
                                        </div>
                                        <p>Adresses de réception</p>
                                    </div>
                                    <div className="content">
                                        <div className="interne" style={{display:this.state.contentInterne}}>
                                            <p>L'adresse de réception par défault est :</p>
                                            <span>{this.state.emailUser}</span>
                                            <p>Vous pouvez ajouter ici d'autres adresses de réception en les séparant par une virgule.</p>
                                            <textarea 
                                                className="champsTest"
                                                type="text"
                                                name="emailsInterne"
                                                value={this.state.emailsInterne}
                                                onChange={this.handleChangeTestInterne}
                                                placeholder="Saisissez de nouvelles adresses…"
                                            />
                                        </div>
                                        <div className="onAcid" style={{display:this.state.contentOnAcid}}>
                                            <p>L'email sera envoyé sur</p>
                                            <span>Account360.runme.0.pn@previews.emailonacid.com</span>
                                            <p>Connectez vous sur EmailOnAcid pour vérifier sa conformité.</p>
                                            <div className="button" onClick={this.openOnAcid}>
                                                <p>Ouvrir EmailOnAcid</p>
                                            </div>
                                        </div>
                                        <div className="bat" style={{display:this.state.contentBat}}>
                                            <p>L'adresse de réception par défault est :</p>
                                            <span>{this.state.emailUser}</span>
                                            <p>Vous pouvez ajouter ici d'autres adresses de réception en les séparant par une virgule.</p>
                                            <textarea 
                                                className="champsTest"
                                                type="text"
                                                name="emailsBat"
                                                value={this.state.emailsBat}
                                                onChange={this.handleChangeBat}
                                                placeholder="Saisissez de nouvelles adresses…"
                                            />
                                            <input
                                                className="subjectBat"
                                                type="text"
                                                name="subjectBat"
                                                required
                                                value={this.state.subjectBat}
                                                onChange={this.handleChangeBat}
                                                placeholder="Objet de l'email"
                                            />
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footerEnvoieMail">
                                <div className="back" onClick={this.cleanInterne}>
                                    <img src="img/back.png"></img>
                                    <p>Retour</p>
                                </div>
                                <div className="buttonEnvoie" onClick={this.envoieMail}>
                                    <p>Envoyer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="envoieOnAcid"  style={{display:this.state.displayEnvoyer}}>
                    <div className="bgEnvoieOnAcid">
                        <div className="containerEnvoieOnAcid">
                            <div className="headerEnvoieOnAcid">
                                <img src="img/envoieIcon.png"></img>
                                <h3>Envoyer un emailing</h3>
                            </div>
                            <div className="containerContent" style={{display:this.state.displayEnvoieDefault}}>
                                <h4>Votre email a bien été envoyé.</h4>
                                <p>Vous devriez le recevoir dans quelques secondes sur votre client de messagerie.</p>
                            </div>
                            <div className="containerContent" style={{display:this.state.displayEnvoieOnAcid}}>
                                <h4>Votre email a bien été envoyé.</h4>
                                <p>Vous devriez le recevoir dans quelques secondes sur EmailOnAcid.</p>
                                <h2 onClick={this.openOnAcid}>Ouvrir EmailOnAcid</h2>
                            </div>
                            <div className="footerEnvoieMail">
                                <div className="back" onClick={this.cleanAccuse}>
                                    <img src="img/back.png"></img>
                                    <p>Retour</p>
                                </div>
                                <div className="buttonEnvoie" onClick={this.reEnvoieMail}>
                                    <p>Nouvel envoi</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cacheButtons">
                    <div className="testButtonsContainer">
                        
                        <form className="mt-5" onSubmit={this.modifier}>
                            <input
                                type="submit"
                                className="btn btn-primary justify-content-center d-flex w-100"
                                value="Modifier"
                            />
                        </form>
                        <form className="mt-5" onClick={this.openInterne}>
                            <input
                                type="button"
                                className="btn btn-primary justify-content-center d-flex w-100"
                                value="Test interne"
                            />
                        </form>
                    </div>
                    <div className="testButtonsContainer">
                        <form className="mt-5" onSubmit={this.testOnAcid}>
                            <input
                                type="submit"
                                className="btn btn-primary justify-content-center d-flex w-100"
                                value="Email on Acid"
                            />
                        </form>
                        <form className="mt-5" onClick={this.openBat}>
                            <input
                                type="button"
                                className="btn btn-primary justify-content-center d-flex w-100"
                                value="Envoyer un BAT"
                            />
                        </form>
                        <form className="mt-5" onSubmit={this.zipper}>
                            <input
                                type="submit"
                                className="btn btn-primary justify-content-center d-flex w-100"
                                value="Zipper"
                            />
                        </form>
                    </div>
                    <div className="testButtonsContainerAlone">
                        <form className="mt-5" onSubmit={this.archiver}>
                            <input
                                type="submit"
                                className="btn btn-primary justify-content-center d-flex w-100"
                                value="Archiver"
                            />
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Test