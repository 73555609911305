import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// Components
import Header from './components/layouts/Header';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import Copy from './components/interfaces/Copy';
import CreateFolder from './components/interfaces/CreateFolder';
import Build from './components/interfaces/Build';
import Datas from './components/interfaces/Datas';
import Content from './components/interfaces/Content';
import Cleaner from './components/interfaces/Cleaner';
import CleanForBAT from './components/interfaces/CleanForBAT';
import Dashboard from './components/files/Dashboard';
import Test from './components/files/Test';
import ZipFolder from './components/files/ZipFolder';
import AccessRegister from './components/auth/AccessRegister';
import {ProtectedRoute} from './components/auth/ProtectedRoute';
import BadGateway from './components/interfaces/Badgateway';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          {/*<Route exact path="/register" component={Register}/>*/}
          <ProtectedRoute exact path="/register" component={Register} />
          <Route exact path="/" component={Login}/>
          <Route exact path="/accessregister" component={AccessRegister}/>
          {/*<ProtectedRoute exact path="/dashboard" component={Dashboard} />
          <ProtectedRoute exact path="/build" component={Build} />
          <ProtectedRoute exact path="/copy" component={Copy}/>
          <ProtectedRoute exact path="/createFolder" component={CreateFolder}/>
          <ProtectedRoute exact path="/datas" component={Datas}/>
          <ProtectedRoute exact path="/dashboard" component={Dashboard}/>
          <ProtectedRoute exact path="/content" component={Content}/>
          <ProtectedRoute exact path="/cleaner" component={Cleaner}/>
          <ProtectedRoute exact path="/cleanForBAT" component={CleanForBAT}/>
          <ProtectedRoute exact path="/test" component={Test}/>
          <ProtectedRoute exact path="/zipFolder" component={ZipFolder}/>*/}
          <Route exact path="/dashboard" component={Dashboard}/>
          <Route exact path="/build" component={Build}/>
          <Route exact path="/copy" component={Copy}/>
          <Route exact path="/createFolder" component={CreateFolder}/>
          <Route exact path="/datas" component={Datas}/>
          <Route exact path="/dashboard" component={Dashboard}/>
          <Route exact path="/content" component={Content}/>
          <Route exact path="/cleaner" component={Cleaner}/>
          <Route exact path="/cleanForBAT" component={CleanForBAT}/>
          <Route exact path="/test" component={Test}/>
          <Route exact path="/zipFolder" component={ZipFolder}/>
          <Route path="*" component={BadGateway}/>
        </Switch>
      </Router>
      
    </div>
  );
}

export default App;
