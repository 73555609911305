import React, { Component } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Header extends Component {
    logOut(e) {
      e.preventDefault()
      localStorage.removeItem('usertoken')
      this.props.history.push(`/`)
    }

    render() {
        const loginRegLink = (
            <header>
                <nav className="navbar navbar-dark bg-header">
                    <div className="containerNav">
                        <Link to="/" className="navbar-brand">
                            Emailing App
                        </Link>
                    </div>
                </nav>
            </header>
          )
      
          const userLink = (
            <header>
                <nav className="navbar navbar-dark bg-header">
                    <div className="containerNav">
                        <Link to="/dashboard" className="navbar-brand">
                            Emailing App
                        </Link>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a href="" onClick={this.logOut.bind(this)} className="nav-link">
                                    Déconnexion
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
            )

            return (
                <div>
                    {localStorage.usertoken ? userLink : loginRegLink}
                </div>
            )
    }
}
export default withRouter(Header)
