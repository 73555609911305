import { log } from 'async';
import React, { Component } from 'react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class ZipComponent extends Component {
    constructor(props){
        super(props);
    }
    state = {
        id: this.props.id,
        nom: '',
        url: '',
        html: false,
        imageHtml: 'img/cloud-upload-off.svg',
        complete: 0,
        border: 'border: solid 1px rgba(215, 211, 223, 0.5)',
        displayImg: 'none',
        remove: false,
        displayElem: 'flex'
    }
    handleChange = e => {
        var value = e.target.value;
        this.setState({
            [e.target.name]: value
        }, () => {
            this.props.callBackFromParent(this.state)
            if(this.state.nom && this.state.url !== ''){
                this.setState({
                    complete: 1,
                    border: '1px solid #ffaa00',
                    displayImg: 'block'
                }, () => {
                    this.props.callBackFromParent(this.state)
                })
                // console.log('it s ok');
            } else {
                this.setState({
                    complete: 0,
                    border: 'solid 1px rgba(215, 211, 223, 0.5)',
                    displayImg: 'none'
                }, () => {
                    //this.props.callBackFromParent(this.state)
                })
                // console.log('it s not ok');
            }
        });
    };
    componentDidUpdate(prevProps) {
        //console.log(this.props.datas);
        if (prevProps.actualize !== this.props.actualize) {
          this.actualisationFromParent();
        }
      }
    actualisationFromParent = () => {
        if(this.props.datas !== undefined){
            console.log(this.props.datas);
            this.setState({
                id: this.props.datas.id,
                nom: this.props.datas.nom,
                url: this.props.datas.url,
                complete: this.props.datas.complete,
                border: this.props.datas.border,
                displayImg: this.props.datas.displayImg,
                remove: this.props.datas.remove,
                displayElem: 'flex',
                html: this.props.datas.html
            })
            if(this.state.html == true) {
                this.setState({
                    imageHtml: 'img/cloud-upload-on.svg'
                })
            } else {
                this.setState({
                    imageHtml: 'img/cloud-upload-off.svg'
                })
            }
        } else {
            this.setState({
                id: Math.random(Math.random(50)),
                nom: '',
                url: '',
                complete: 0,
                border: 'border: solid 1px rgba(215, 211, 223, 0.5)',
                displayImg: 'none',
                remove: false,
                displayElem: 'flex'
            })
        }
        
    }
    removeSupport = () => {
        console.log('click');
        this.setState({
            remove: true,
            displayElem: 'flex'
        }, () => {
            this.props.callBackFromParent(this.state)
        })
    }
    sendToParent = () => {
        this.props.callBackFromParent(this.state)
    }
    downloadHtml = () => {
        
        if(this.state.html == false){
            this.setState({
                html: true,
                imageHtml: 'img/cloud-upload-on.svg'
            }, () => {
                this.props.callBackFromParent(this.state);
                console.log('ZipComponent : html : ', this.state.html);
            })
        } else {
            this.setState({
                html: false,
                imageHtml: 'img/cloud-upload-off.svg'
            }, () => {
                this.props.callBackFromParent(this.state);
                console.log('ZipComponent : html : ', this.state.html);
            })
        }
    }
    render() {
        const { nom, url } = this.state;
        return (
            <div className="container-zipLine" style={{display: this.state.displayElem}}>
                <div className="containerChampsZipFolder" style={{border: this.state.border}}>
                    <div className="leftSide">
                        <div className="inputWithIcon">
                            <input
                                id="input-nom"
                                className="questionnaire"
                                type="text"
                                name="nom"
                                value={nom}
                                onChange={this.handleChange}
                                autoComplete="off"
                            />
                            <label htmlFor="input-nom"><span>Nom du support</span></label>
                            <img src="img/ic-prom.png"></img>
                        </div>
                        {/*<img src="img/ic-prom.png"></img>
                        <input 
                            type="text"
                            id="nom"
                            name="nom"
                            value={nom}
                            onChange={this.handleChange}
                            required 
                            autoComplete="off"
                            className={'question'}
                        />
                        <label for="nom"><span>Nom du support</span><img src="./img/doneIcon.png"></img></label>*/}
                    </div>
                    <div className="separateBoth"></div>
                    <div className="rightSide">
                        <div className="inputWithIcon">
                            <input
                                id="input-url"
                                className="questionnaire"
                                type="text"
                                name="url"
                                value={url}
                                onChange={this.handleChange}
                                autoComplete="off"
                            />
                            <label htmlFor="input-url"><span>URL de redirection</span><img style={{display:this.state.displayImg}} src="./img/doneIcon.png"></img></label>
                            <img src="img/link.png"></img>
                        </div>
                    </div>
                </div>
                <div className="deleteFolderIcon" onClick={this.downloadHtml}><img src={this.state.imageHtml}></img></div>
                <div className="deleteFolderIcon" onClick={this.removeSupport}><img src="img/trash.png"></img></div>
            </div>
        )
    }
}

export default ZipComponent