import React, { Component } from 'react';
import Projets from './Projects'
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import access from '../auth/Access';
import SearchInput, {createFilter} from 'react-search-input';
const cookies = new Cookies();


class Dashboard extends Component {
    constructor(props, context){
        super(props, context);
        this.state = {
            projects: [],
            userId: 0,
            username: '',
            globalProj: [],
            archiveProjet: [],
            actif: true,
            searchTerm: '',
            displayCreation: "none",
            job: '####',
            programme: '',
            promoteur: '',
            ville:'',
            selectedOption: 'default',
            projCreaId: '',
            allColor: '#ffffff',
            myColor: '#33225d',
            userChoix: 'allProjects',
            myColorBg: 'transparent',
            allColorBg: '#33225d',
            displayChoixMy: 'none',
            displayChoixAll: 'block',
            bgDefault: '#33225d',
            borderDefault: '1px solid #33225d',
            borderCogedim: 'solid 1px rgba(215, 211, 223, 0.5)',
            imgDefault: './img/doneIcon.png',
            typoDef: '#ffffff',
            bgCogedim: '#ffffff',
            imgCogedim: './img/ic-cog.png',
            typocog: '#777777',
            displayAlertMsg: "none",
            folderReg: '',
            page: 0,
            currentPage: 0,
            totalPages: 0,
            selectedType: 'current',
            selectedUser: 'all',
            displayPagination: 'flex',
            justify: 'space-between'
        }
    }
    async componentDidMount() {
        const token = localStorage.usertoken
            const decoded = jwt_decode(token)
            cookies.set("userId", decoded.id)
            cookies.set("username", decoded.username)
            cookies.set("email", decoded.email)
            let getProjectsNotArchive = await fetch(`/api/projects/paginated/status/${this.state.selectedType}/user/${this.state.selectedUser}/page/${this.state.page}`);
            let getProjectsNotArchiveJSON = await getProjectsNotArchive.json();
            console.log('Nombres de proj = ' , getProjectsNotArchiveJSON.data.totalItems);
            console.log('Nombres de page = ' , getProjectsNotArchiveJSON.data.totalPages + 1);
            console.log('Current page = ' , getProjectsNotArchiveJSON.data.currentPage + 1);
            this.setState({
                userId: decoded.id,
                username: decoded.username,
                currentPage: getProjectsNotArchiveJSON.data.currentPage + 1,
                totalPages: getProjectsNotArchiveJSON.data.totalPages
            })
    }
    
    activeDefault = () => {
        if(this.state.selectedOption === 'default') {
            // // console.log('default');
        } else {
            this.setState({
                selectedOption: 'default',
                bgDefault: '#33225d',
                borderDefault: '1px solid #33225d',
                borderCogedim: 'solid 1px rgba(215, 211, 223, 0.5)',
                imgDefault: './img/doneIcon.png',
                typoDef: '#ffffff',
                bgCogedim: '#ffffff',
                imgCogedim: './img/ic-cog.png',
                typocog: '#777777'
            })
        }
    }
    activeCogedim = () => {
        if(this.state.selectedOption === 'cogedim') {
            // console.log('cogedim');
        } else {
            this.setState({
                selectedOption: 'cogedim',
                bgDefault: '#ffffff',
                borderCogedim: '1px solid #33225d',
                borderDefault: 'solid 1px rgba(215, 211, 223, 0.5)',
                imgDefault: './img/ic-default.png',
                typoDef: '#777777',
                bgCogedim: '#33225d',
                imgCogedim: './img/doneIcon.png',
                typocog: '#ffffff'
            })
        }
    }
    /** Actualise les states des champs lors d'un changement dans le form */
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    handleOptionChange = e => {
        this.setState({
          selectedOption: e.target.value
        }, () => {
            // console.log(this.state.selectedOption);
            
        });
    };
    /** Choix des projets à afficher */
    userChoiceMy = async () => {
        if(this.state.userChoix === 'myProjects') {
            return
        }
        if(this.state.userChoix === 'allProjects') {
            let getProjectsNotArchive = await fetch(`/api/projects/paginated/status/${this.state.selectedType}/user/${this.state.username}/page/0`);
            let getProjectsNotArchiveJSON = await getProjectsNotArchive.json();
            this.setState({
                myColor: '#ffffff',
                allColor: '#33225d',
                myColorBg: '#33225d',
                allColorBg: 'transparent',
                userChoix: 'myProjects',
                displayChoixAll: 'none',
                displayChoixMy: 'block',
                selectedUser: this.state.username,
                searchTerm: '',
                displayPagination: 'flex',
                justify: 'space-between',
                page: 0,
                currentPage: 1,
                totalPages: getProjectsNotArchiveJSON.data.totalPages
            }, () => {
                document.getElementById('searchId').value = '';
                this.refs.encoursFunction.searchUpdated(this.state.searchTerm)
            })
        }
    }
    userChoiceAll = async () => {
        if(this.state.userChoix === 'myProjects') {
            let getProjectsNotArchive = await fetch(`/api/projects/paginated/status/${this.state.selectedType}/user/all/page/0`);
            let getProjectsNotArchiveJSON = await getProjectsNotArchive.json();
            this.setState({
                myColor: '#33225d',
                allColor: '#ffffff',
                myColorBg: 'transparent',
                allColorBg: '#33225d',
                userChoix: 'allProjects',
                displayChoixMy: 'none',
                displayChoixAll: 'block',
                selectedUser: 'all',
                searchTerm: '',
                displayPagination: 'flex',
                justify: 'space-between',
                page: 0,
                currentPage: 1,
                totalPages: getProjectsNotArchiveJSON.data.totalPages
            }, () => {
                document.getElementById('searchId').value = '';
                this.refs.encoursFunction.searchUpdated(this.state.searchTerm)
            })
        }
        if(this.state.userChoix === 'allProjects') {
            return
        }
    }
    pagePrec = () => {
        if(this.state.page === 0){}
        else {
            this.setState({
                page: this.state.page - 1,
                currentPage: this.state.currentPage - 1
            })
        }
    }
    pageSuiv = () => {
        if(this.state.page === this.state.totalPages - 1){}
        else {
            this.setState({
                page: this.state.page + 1,
                currentPage: this.state.currentPage + 1
            })
        }
    }
    /** Envoie l'état de l'onglet à l'enfant Projects */
    ongletEncoursActif = async () => {
        if(this.state.actif === false) {
            let getProjectsNotArchive = await fetch(`/api/projects/paginated/status/current/user/${this.state.selectedUser}/page/0`);
            let getProjectsNotArchiveJSON = await getProjectsNotArchive.json();
            this.setState({
                actif: true,
                searchTerm: '',
                displayPagination: 'flex',
                justify: 'space-between',
                page: 0,
                currentPage: 1,
                totalPages: getProjectsNotArchiveJSON.data.totalPages,
                selectedType: 'current'
            }, () => {
                this.refs.encoursFunction.ongletEncours(this.state.actif)
                document.getElementById('searchId').value = '';
                this.refs.encoursFunction.searchUpdated(this.state.searchTerm)
                // console.log(this.state.actif);
            })
        }
        if(this.state.actif === true) {
            // console.log(this.state.actif);
        }
    }
    ongletEncoursInactif = async () => {
        console.log(this.state.selectedUser);
        let getProjectsNotArchive = await fetch(`/api/projects/paginated/status/archive/user/${this.state.selectedUser}/page/0`);
        let getProjectsNotArchiveJSON = await getProjectsNotArchive.json();
        if(this.state.actif === false) {
            // console.log(this.state.actif);
        }
        if(this.state.actif === true) {
            this.setState({
                actif: false,
                searchTerm: '',
                displayPagination: 'flex',
                justify: 'space-between',
                page: 0,
                currentPage: 1,
                totalPages: getProjectsNotArchiveJSON.data.totalPages,
                selectedType: 'archive'
            }, () => {
                this.refs.encoursFunction.ongletEncours(this.state.actif)
                document.getElementById('searchId').value = '';
                this.refs.encoursFunction.searchUpdated(this.state.searchTerm)
                // console.log(this.state.searchTerm);
            })
        }
    }
    /** Envoie la recherche effectuée à l'enfant Projects */
    searchUpdated = (term) => {
        if(term === '') {
            this.setState({
                searchTerm: '',
                displayPagination: 'flex',
                justify: 'space-between',
            }, () => {
                this.refs.encoursFunction.searchUpdated(this.state.searchTerm)
                this.refs.encoursFunction.getProjects()
                // console.log(this.state.searchTerm);
            })
        } else {
            this.setState({
                searchTerm: term,
                displayPagination: 'none',
                justify: 'flex-end'
            }, () => {
                this.refs.encoursFunction.searchUpdated(this.state.searchTerm)
            })
        }
        
        
    }
    /** Fonctions création */
    handleSubmit = async e => {
        e.preventDefault();
        var programme = this.state.programme;
        var promoteur = this.state.promoteur;
        var d = new Date();
        var month = d.getMonth();
        month = (month + 1).toString();
        if (month.length === 1){ 
            month = "0" + month;
        }
        var year = d.getFullYear().toString().substr(-2);
        var full = year + month;
        var folderName = full + '_' + programme + '_' + promoteur;
        // console.log(folderName);
        if(this.state.ville == '' || this.state.programme == '' || this.state.promoteur == '') {
            // console.log("erreur champs");
            
            this.setState({
                displayAlertMsg: "block"
            })
        } else {
            const create = {
                username: cookies.get("username"),
                userId: cookies.get("userId"),
                folder: folderName,
                programme: programme,
                promoteur: promoteur,
                ville: this.state.ville,
                projId: this.state.projCreaId,
                type: this.state.selectedOption,
                job: this.state.job,
                status: 'En conception'
            };
            // console.log(create);
         
            await this.createFolder(create);
            await this.createProject(create);
            await this.copy(create);
            //await this.replaceTitle(create);
            this.redirect(create);
        }
        
    }
    replaceTitle = async (create) => {
        let response = await fetch("/api/gulpRoute/replaceTitle", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: create.username,
                folder: create.folder,
                promoteur: create.promoteur
            })
        })
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            this.setState({
                folderReg: result.dossier
            }, () => {
                console.log(this.state.folderReg);
            })
            console.log(result);
        }
        return;
    }
    createFolder = async (create) => {
        let response = await fetch("/api/directory/createFolder", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: create.username,
                folder: create.folder
            })
        })
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            this.setState({
                folderReg: result.dossier
            }, () => {
                console.log(this.state.folderReg);
            })
            console.log(result);
        }
        return;
    }
    createProject = async (create) => {
        let response = await fetch("/api/projects/create",
        {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                userId: create.userId,
                ville: this.state.ville,
                campagne: create.programme,
                promoteur: create.promoteur,
                folder: this.state.folderReg,
                type: create.type,
                userCreator: create.username,
                userUpdator: create.username,
                job: create.job,
                url: null,
                mailBat: null,
                mailInterne: null,
                support: null
            })
        }
        )
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
            cookies.set("projectId", result.data.id);
            cookies.set("type", result.data.type);
            this.setState({
                projCreaId: result.data.id
            })
        }
    }
    copy = async (create) => {
        let response = await fetch("/api/directory/recursiveCopy", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folderDest: this.state.folderReg,
                username: create.username,
                type: create.type
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
    }
    redirect = (create) => {
        return this.props.history.push('/build', { status: create.status, userCreator: create.username, userUpdator: create.username, folderPath: this.state.folderReg, type: create.type, programme: create.programme, promoteur: create.promoteur, ville: create.ville, jobNumber: create.job })
    }
    /** Gestion de la popUp création */
    closeCreation = () => {
        this.setState({
            displayCreation: "none",
            programme: '',
            promoteur: '',
            ville:'',
        })
    }
    openCreation = () => {
        this.setState({
            displayCreation: "block"
        })
    }
    render() {
        let encours = '';
        let archives = '';
        if(this.state.actif === true) {
            encours = 'projetsEnCours ongletActif';
            archives = 'projetsArchives';
        }
        if(this.state.actif === false) {
            encours = 'projetsEnCours';
            archives =  'projetsArchives ongletActifVert'; 
        }
        return (
            <div>
                <div className="creationPop" style={{display:this.state.displayCreation}}>
                    <div className="bgCreationPop">
                        <div className="containerContent">
                            <div className="headerPop">
                                <img src="img/letterCreation.png"></img>
                                <h2>Créer un emailing</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="formsPop">
                                    <div className="leftForm">
                                        <div className="titleForm">
                                            <div className="para">
                                                <p>1</p>
                                            </div>
                                            <h3>Informations</h3>
                                        </div>
                                        <div className="inputWithIcon">
                                            <input
                                                id="input-job"
                                                className="questionnaire"
                                                type="number"
                                                name="job"
                                                value={this.state.job}
                                                onChange={this.handleChange}
                                                autoComplete="off"
                                            />
                                            <label for="input-job"><span>Job</span><img src="./img/doneIcon.png"></img></label>
                                            <img src="img/ic-id.png"></img>
                                        </div>
                                        <div className="inputWithIcon">
                                            <input
                                                id="input-promoteur"
                                                className="questionnaire"
                                                type="text"
                                                name="promoteur"
                                                value={this.state.promoteur}
                                                onChange={this.handleChange}
                                                required 
                                                autoComplete="off"
                                            />
                                            <label for="input-promoteur"><span>Promoteur</span><img src="./img/doneIcon.png"></img></label>
                                            <img src="img/ic-prom-2.png"></img>
                                        </div>
                                        <div className="inputWithIcon">
                                            <input
                                                id="input-programme"
                                                className="questionnaire"
                                                type="text"
                                                name="programme"
                                                value={this.state.programme}
                                                onChange={this.handleChange}
                                                required 
                                                autoComplete="off"
                                            />
                                            <label for="input-programme"><span>Nom du projet</span><img src="./img/doneIcon.png"></img></label>
                                            <img src="img/prog.png"></img>
                                        </div>
                                        <div className="inputWithIcon">
                                            <input
                                                id = "input-ville"
                                                type="text"
                                                name="ville"
                                                className="questionnaire"
                                                value={this.state.ville}
                                                onChange={this.handleChange}
                                                required 
                                                autoComplete="off"
                                            />
                                            <label for="input-ville"><span>Ville</span><img src="./img/doneIcon.png"></img></label>
                                            <img src="img/ville.png"></img>
                                        </div>
                                        
                                    </div>
                                    <div className="rightForm">
                                        <div className="titleForm">
                                            <div className="para">
                                                <p>2</p>
                                            </div>
                                            <h3>Template de base</h3>
                                        </div>
                                        <div className="choixTemplate" style={{backgroundColor:this.state.bgDefault, border: this.state.borderDefault}} onClick={this.activeDefault}>
                                            <img src={this.state.imgDefault}></img>
                                            <p  style={{color:this.state.typoDef}}>Par Défaut</p>
                                            <div className="emptyDiv"></div>
                                        </div>
                                        <div className="choixTemplate" style={{backgroundColor:this.state.bgCogedim, border: this.state.borderCogedim}} onClick={this.activeCogedim}>
                                            <img src={this.state.imgCogedim}></img>
                                            <p  style={{color:this.state.typocog}}>Cogedim</p>
                                            <div className="emptyDiv"></div>
                                        </div>
                                        <div className="radioPara">
                                            <p>Ce template sera la base de votre email</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="containerButtons">
                                    <div 
                                        onClick={this.closeCreation}
                                        className="retourDash"    
                                    >
                                        <img src="img/back.png"></img>
                                        <p>Retour au Dashboard</p>
                                    </div>
                                    <span className="alertMsgCreation" style={{display: this.state.displayAlertMsg}}>Veuillez renseigner tous les champs</span>
                                    <input
                                        type="submit"
                                        className="btn btn-primary justify-content-center d-flex"
                                        value="Créer un emailing"
                                    />
                                </div>
                            </form>
                            <div className="footerPop">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="headerDash">
                    <section className="emailings">
                        <div className="container">
                            <div className="new">
                            <div className="blocTitre">
                                <div className="titleDashboard">
                                    <img src="./img/icon-emailing.svg"></img>
                                    <h1>Emailings</h1>
                                </div>
                                
                                <p>Toutes vos conceptions emailings en cours et archivées</p>
                            </div>
                            <div className="blocCreation">
                                <a onClick={this.openCreation}>
                                    <p>Créer un emailing</p>
                                    <img src="./img/icon-plus.svg"></img>
                                </a>
                            </div>
                            </div>
                        </div>
                    </section>
                    <div className="gestionProjets">
                        <div className="ongletsProjets">
                            <div className={encours}>
                                <p onClick={this.ongletEncoursActif}>En cours</p>
                            </div>
                            <div className={archives}>
                                <p onClick={this.ongletEncoursInactif}>Archives</p>
                            </div>
                        </div>


                        <div className="containerSearchInput">
                            <img src="./img/ic-searchInput.png"></img>
                            <SearchInput className="search-input" id="searchId" placeholder="Rechercher un projet ..." onChange={this.searchUpdated} />
                            <div className="searchBorder"></div>
                        </div>



                    </div>
                </div>
                <div className="bgUserChoice">
                    <div className="userChoice" style={{justifyContent: this.state.justify}}>
                        <div className="pageChoice" style={{display: this.state.displayPagination}}>
                            <div className="pagination-arrow reverse" onClick={this.pagePrec}>
                                <img src="./img/arrow_orange.svg"></img>
                            </div>
                            <p>{this.state.currentPage} / {this.state.totalPages}</p>
                            <div className="pagination-arrow" onClick={this.pageSuiv}>
                                <img src="./img/arrow_orange.svg"></img>
                            </div>
                        </div>
                        <div className="projectChoice">
                            <div className="userLeft" style={{backgroundColor:this.state.allColorBg}} onClick={this.userChoiceAll}>
                                <p style={{color:this.state.allColor}}>Tous les projets</p>
                            </div>
                            <div className="userRight" style={{backgroundColor:this.state.myColorBg}} onClick={this.userChoiceMy}>
                                <p style={{color:this.state.myColor}}>Mes projets</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="bgContainerProj bgContainerProj-pagination">
                    <Projets
                        ref="encoursFunction" 
                        userChoix={this.state.userChoix} 
                        displayChoixMy={this.state.displayChoixMy} 
                        displayChoixAll={this.state.displayChoixAll} 
                        userId={this.state.userId} 
                        history={this.props.history} 
                        actif={this.state.actif}
                        selectedType={this.state.selectedType}
                        selectedUser={this.state.selectedUser}
                        page={this.state.page}
                    ></Projets>
                </div>
                <div className="bgUserChoice">
                    <div className="userChoice bottom-choice" style={{justifyContent: this.state.justify}}>
                        <div className="pageChoice" style={{display: this.state.displayPagination}}>
                            <div className="pagination-arrow reverse" onClick={this.pagePrec}>
                                <img src="./img/arrow_orange.svg"></img>
                            </div>
                            <p>{this.state.currentPage} / {this.state.totalPages}</p>
                            <div className="pagination-arrow" onClick={this.pageSuiv}>
                                <img src="./img/arrow_orange.svg"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Dashboard