import React, { Component } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

class GetImage extends Component {
    constructor(props){
        super(props);
    }
    state = {
        displayPreview: "none",
        width: '',
        height: ''
    }
    showPreview = () => {
        this.setState({
            displayPreview: 'flex'
        });
    }
    unShowPreview = () => {
        this.setState({
            displayPreview: 'none'
        });
    }
    copyName = () => {
        let copyText = this.props.name;
        copyText.select();
        document.execCommand("copy");
    }
    imgEl = React.createRef();
    render() {
        let displayName = "";
        let imgName = this.props.name.split('.')[0];
        let src = "./img/previewPartials/"+this.props.type+"/"+imgName+".jpg";
        let other = this.props.name.split('.')[1];
        
        if(other !== "html") {
            displayName = "none";
        } else {
            displayName = "block";
        }
        return (
                <div style={{display:displayName}} className="itemImageContent" onMouseEnter={this.showPreview} onMouseLeave={this.unShowPreview}>
                    <div className="containerImage">
                    
                    <img src={`https://www.emailingmanager-360.net/api/server/img/${this.props.username}/${this.props.folder}/${this.props.name}`}></img>
                        {/*<img src={`http://emailingmanager-360.net/:5000/api/directory/getURL/user/${this.props.username}/project/${this.props.folder}/images/${this.props.name}`}></img>*/}
                    </div>
                    
                    <CopyToClipboard className="getImagesContent" onCopy={this.onCopy} text={`<img src="https://www.emailingmanager-360.net/api/server/img/${this.props.username}/${this.props.folder}/${this.props.name}" width="${this.state.width}" height="${this.state.height}" border="0" style="display: block; width: ${this.state.width}px; height: ${this.state.height}px;" alt="@@@" class="mobImgResized" />`}>
                        <span>{this.props.name}</span>
                    </CopyToClipboard>
                    <p>Copier</p>
                    <div style={{display:this.state.displayPreview}} className="containerPreviewImages">
                        <img 
                            src={`https://www.emailingmanager-360.net/api/server/img/${this.props.username}/${this.props.folder}/${this.props.name}`}
                            ref={this.imgEl}
                            onLoad={() => this.setState({ height : this.imgEl.current.naturalHeight, width : this.imgEl.current.naturalWidth})}
                        ></img>
                        <p>{this.state.width} x {this.state.height}</p>
                    </div>
                </div>
        )
    }
}

export default GetImage