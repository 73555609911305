import React, { Component } from 'react';
import axios from 'axios';

class Datas extends Component {
    state = {
        title: '',
        preheader: '',
        email_url: '',
        errors: {}
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        var folder = this.props.location.state.folderPath;
        const split = folder.split('_');
        const title = split[2] + ' - ' + split[1];
        
        const datas = {
            username: this.props.location.state.username,
            folder: folder,
            type: this.props.location.state.type,
            title: title,
            preheader: this.state.preheader
        };
        // console.log(datas.folder);

        

        
        
        
        axios
            .post('/api/gulpRoute/beautify', datas);

        axios
            .post('/api/gulpRoute/insertDatas', datas)
            .then(res => this.props.history.push('/cleaner', { username: datas.username, folderPath: datas.folder, type: datas.type }));
    /*    axios
            .post('/api/gulpRoute/cleanImg', datas)
            .then(res => this.props.history.push('/cleaner', { username: datas.username, folderPath: datas.folder })); */
    };

    render() {
        const { preheader, errors } = this.state;
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="mt-5 text-center mb-0">
                            <h2 className="mt-5 text-center mb-0">Copy your directory</h2>
                            <p className="text-lead text-center">Choose your directory</p>
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        name="preheader"
                                        value={preheader}
                                        onChange={this.handleChange}
                                        className={
                                            errors.type === "error" 
                                            ? 'is-invalid form-control' 
                                            : 'form-control'
                                        }
                                        placeholder="Preheader (you can put HTML here)"
                                    />
                                    <span className="invalid-feedback">{errors.msg}</span>
                                </div>
                                <input
                                        type="submit"
                                        className="btn btn-primary justify-content-center d-flex w-100"
                                        value="Insérer"
                                    />
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Datas