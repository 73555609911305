import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import ZipComponents from './ZipComponents';
import Loader from 'react-loader-spinner';
import access from '../auth/Access.js';
import { log } from 'async';
const cookies = new Cookies();
const Loadering = () => <div className="containerLoading">
                            <div
                                    className="loading"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                <Loader type="ThreeDots" color="#2BAD60" />
                            </div>
                        </div>;
class ZipFolder extends Component {
    constructor(props){
        super(props);
    }
    state = {
        numSupport: 1,
        oldUrl : '',
        data: [{html: false}],
        folderPath: '',
        userCreator: '',
        actualize: false,
        dataFromChild: []
    }
    async componentDidMount(){
        if ( localStorage.getItem('usertoken') == null ) {
            return this.props.history.push('/')
        } else {   
            let projId = cookies.get("projectId");
            let response = await fetch(`/api/projects/${projId}`);
            let result = await response.json();
            // console.log(result.data.userCreator, result.data.folder);
            
            if(result.data.support !== null){
                if(JSON.parse(result.data.support).length > 0) {
                    console.log(result.data.support);
                    console.log(JSON.parse(result.data.support).length);
                    console.log(JSON.parse(result.data.support));
                    console.log(JSON.parse(result.data.support).length);
                    this.setState({
                        data: JSON.parse(result.data.support),
                        numSupport: JSON.parse(result.data.support).length,
                        oldUrl: result.data.url,
                        folderPath: result.data.folder, 
                        userCreator: result.data.userCreator,
                        actualize: true
                    }, () => {
                        this.setState({
                            actualize: false
                        })
                    })
                    console.log(this.state.data);
                } else {
                    console.log('here');
                    this.setState({
                        numSupport: 1,
                        data: [],
                        oldUrl: result.data.url,
                        folderPath: result.data.folder, 
                        userCreator: result.data.userCreator,
                    })
                    
                }
                
            } else {
                //console.log('null');
                this.setState({
                    oldUrl: result.data.url,
                    folderPath: result.data.folder, 
                    userCreator: result.data.userCreator
                })
            }
            let objCookies = cookies.getAll();
            let allCookies = Object.keys(objCookies);
        }
    }
    addSupport = () => {
        if(this.state.data[this.state.data.length-1] !== undefined) {
            // console.log(this.state.numSupport);
            if(this.state.numSupport ===this.state.data.length && this.state.data[this.state.data.length-1].complete === 1){
                this.setState({
                    numSupport: this.state.numSupport + 1
                })
            } else {
                alert(`Veuillez renseigner tous les champs`);
            }
        } else {
            alert('Veuillez renseigner tous les champs');
        }
    }
    removeSupport = () => {
        console.log(this.state.data);
      /*  this.setState({
            numSupport: this.state.numSupport - 1
        })
        this.state.data.pop()*/
    }
    zipFunction = async e => {
        this.setState({
            isLoading: true
        })
        e.preventDefault();
        const content = {
            username: this.state.userCreator,
            folder: this.state.folderPath,
            oldUrl: this.state.oldUrl,
            data: this.state.data,
            projId: cookies.get("projectId")
        }; 
        console.log(this.state.data);
        if((content.data.length === 1)&&(content.data[0].url === '' && content.data[0].nom === '')){
            content.data.nom = content.folder.split('_')[1] + '_' + content.folder.split('_')[2];
            console.log('in');
            console.log(content);
            console.log(this.state.data[0]);
                let correctData = {
                    nom: content.folder.split('_')[1] + '_' + content.folder.split('_')[2]
                };

                await this.createExports(content);
                await this.createTampon(content, correctData);
                await this.copyFile(content, correctData);
                await this.deployImageFtp(content);
                for(let i=0; i<this.state.data.length; i++){
                    console.log(this.state.data[i]);
                    if(this.state.data[i].html == true) {
                        console.log('ZipFolder : correctData empty true : ', this.state.data[i].html);
                        await this.replaceZipNoNameImage(content, correctData);
                    } else {
                        await this.replaceZipImage(content, correctData);
                        console.log('ZipFolder : correctData empty false : ', this.state.data[i].html);
                    }
                }
                await this.zipMultipleFolder(content, correctData);
                await this.deleteMultiFolder(content, correctData);
                await this.updateDbSupports(content);
                await this.zipFolder(content);
                //await this.deleteFolder(content, correctData);
                await this.download(content);
                await this.deleteFolder(content);
                this.setState({
                    isLoading: false
                })
                this.redirect(content);
        } else if (content.data.length > 0){
            /*for(let y = 0; y < content.data.length; y++){
                content.data[y].nom = content.folder.split('_')[1] + '_' + content.folder.split('_')[2]+'_'+content.data[y].nom;
            }*/
            let verif = false;
            for(let i = 0; i < content.data.length; i++){
                console.log(content.data[i].url);
                console.log(content.data[i].nom);
                if(content.data[i].url === '' || content.data[i].nom === ''){
                    verif = true;
                    console.log(verif);
                }
                console.log(verif);
            }
            console.log(this.state.data);
            if(verif === true){
                console.log(verif);
                this.setState({
                    isLoading: false
                })
                alert('Veuillez renseigner tous les champs !')    
            } else {
                console.log(verif);
                let deploy = false;
                for (let j = 0; j < content.data.length; j++) {
                    if(content.data[j].html == true){ deploy = true;}
                }
                if(deploy == true) {
                    console.log('username', content.username);
                    await this.deployImageFtp(content);
                }
                for (let j = 0; j < content.data.length; j++) {
                    let correctData = content.data[j];
                    console.log(this.state.data[j]);
                    if(this.state.data[j].html == true) {
                        console.log('ZipFolder : correctData notEmpty true : ', this.state.data[j].html);
                        await this.createExports(content);
                        await this.createTampon(content, correctData);
                        await this.copyFile(content, correctData);
                        await this.replaceUrl(content, correctData);
                        await this.replaceZipNoNameImage(content, correctData);
                        await this.zipMultipleFolder(content, correctData);
                        await this.deleteMultiFolder(content, correctData);
                    } else {
                        console.log('ZipFolder : correctData notEmpty false : ', this.state.data[j].html);
                        await this.createExports(content);
                        await this.createTampon(content, correctData);
                        await this.copyFile(content, correctData);
                        await this.replaceUrl(content, correctData);
                        await this.replaceZipImage(content, correctData);
                        await this.copyImages(content, correctData);
                        await this.zipMultipleFolder(content, correctData);
                        await this.deleteMultiFolder(content, correctData);
                    }                   
                } 
                await this.updateDbSupports(content);
                await this.zipFolder(content);
                await this.download(content);
                await this.deleteFolder(content);
                this.setState({
                    isLoading: false
                })
                this.redirect(content);   
            }
        } else if(content.data.length === 0){
            content.data.nom = content.folder.split('_')[1] + '_' + content.folder.split('_')[2];
            console.log('in');
            console.log(content);
                let correctData = {
                    nom: content.folder.split('_')[1] + '_' + content.folder.split('_')[2]
                };
                await this.createExports(content);
                await this.createTampon(content, correctData);
                await this.copyFile(content, correctData);
                await this.copyImages(content, correctData);
                for(let i=0; i<this.state.data; i++){
                    if(this.state.data[i].html == true) {
                        console.log('ZipFolder : correctData notEmpty lastEmpty false : ', this.state.data[i].html);
                        await this.replaceZipNoNameImage(content, correctData);
                    } else {
                        await this.replaceZipImage(content, correctData);
                        console.log('ZipFolder : correctData notEmpty lastEmpty false : ', this.state.data[i].html);
                    }
                }
                
                await this.zipMultipleFolder(content, correctData);
                await this.deleteMultiFolder(content, correctData);
                await this.updateDbSupports(content);
                await this.zipFolder(content);
                //await this.deleteFolder(content, correctData);
                await this.download(content);
                await this.deleteFolder(content);
                this.setState({
                    isLoading: false
                })
                this.redirect(content);
        }
    }
    updateDbSupports = async (content) =>{
        let response = await fetch(`/api/projects/${content.projId}`, {
            headers : {
                "Content-Type": "application/json"
            },
            method: "PATCH",
            body: JSON.stringify({
                support: JSON.stringify(this.state.data)
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            console.log('Enregistré avec succès : ' , result);
            // alert('Enregistré avec succès : ' , result)
        }
    }
    createExports = async (content) =>{
        let response = await fetch("/api/gulpRoute/createExports", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: content.username,
                folder: content.folder
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
        return;
    }
    createTampon = async (content, correctData) =>{
        let response = await fetch("/api/gulpRoute/createTampon", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: content.username,
                folder: content.folder,
                support: correctData.nom
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
        return;
    }
    copyFile = async (content, correctData) =>{
        let response = await fetch("/api/directory/copyFile", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: content.username,
                folder: content.folder,
                support: correctData.nom
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
        return;
    }
    replaceUrl = async (content, correctData) =>{
        let response = await fetch("/api/gulpRoute/replaceUrl", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: content.username,
                folder: content.folder,
                support: correctData.nom,
                oldUrl: content.oldUrl,
                newUrl: correctData.url
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
        return;
    }
    replaceZipImage = async (content, correctData) =>{
        let response = await fetch("/api/gulpRoute/replaceImageZip", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: content.username,
                folder: content.folder,
                support: correctData.nom,
                oldUrl: content.oldUrl,
                newUrl: correctData.url
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
        return;
    }
    replaceZipNoNameImage = async (content, correctData) =>{
        let response = await fetch("/api/gulpRoute/replaceImageWithoutNameZip", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: content.username,
                folder: content.folder,
                support: correctData.nom,
                oldUrl: content.oldUrl,
                newUrl: correctData.url
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
        return;
    }
    deployImageFtp = async (content) => {
        // console.log(content);
        let response = await fetch("/api/gulpRoute/deployWithoutNameOnFtp", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.username,
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    copyImages = async (content, correctData) =>{
        let response = await fetch("/api/directory/copyFolderImg", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: content.username,
                folder: content.folder,
                support: correctData.nom
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
        return;
    }
    redirectCancel =  () => {
        const content = {
            username: this.state.userCreator,
            folder: this.state.folderPath,
            oldUrl: this.state.oldUrl,
            data: this.state.data
        };
        console.log(content.username);
        this.redirect(content);
    }
    redirect = (content) => {
        return this.props.history.push('/test', { userCreator: content.username, folderPath: content.folder })
    }
    zipFolder = async (content, correctData) =>{
        let response = await fetch("/api/gulpRoute/zipFolder", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: content.username,
                folder: content.folder,
                //support: correctData.nom
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
        return;
    }
    zipMultipleFolder = async (content, correctData) =>{
        let response = await fetch("/api/gulpRoute/zipMultiFolder", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: content.username,
                folder: content.folder,
                support: correctData.nom
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
        return;
    }
    deleteMultiFolder = async (content, correctData) =>{
        let response = await fetch("/api/directory/deleteMultiFolderZip", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "DELETE",
            body: JSON.stringify({
                username: content.username,
                folder: content.folder,
                support: correctData.nom
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
        return;
    }
    deleteFolder = async (content) =>{
        let response = await fetch("/api/directory/deleteFolderZip", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "DELETE",
            body: JSON.stringify({
                username: content.username,
                folder: content.folder,
                // support: correctData.nom
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
        return;
    }
    download = async (content, correctData) =>{
        let response = await fetch("/api/gulpRoute/downloadZip", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: content.username,
                folder: content.folder
            })
        })
        if (response.status === 200 || response.status === 204){
            let result = await response.blob()
            var url = window.URL.createObjectURL(result);
            var a = document.createElement('a');
            a.href = url;
            a.download = `_exports.zip`;
            document.body.appendChild(a);
            a.click();    
            a.remove();
            // console.log(result);
        }
        return;
    }
    callBack = (dataFromChild) => {
        console.log('ZipFolder : dataFromChild : ', dataFromChild);
        this.setState({
            dataFromChild: dataFromChild
        })
        if (this.state.numSupport > this.state.data.length) {
            this.state.data.push(dataFromChild)
        }
        if (this.state.numSupport = this.state.data.length) {
            this.state.data[this.state.data.findIndex(x => x.id === dataFromChild.id)] = dataFromChild
            if (dataFromChild.remove == true) {
                if(this.state.data.length == 1) {
                    this.setState({
                        data: [],
                        actualize: true
                    }, () => {
                        this.setState({
                            actualize: false
                        })
                    })
                } else {
                    var array = [...this.state.data];
                    array.splice(array.indexOf(dataFromChild) , 1);
                    console.log(this.state.data);
                    this.setState({
                        data: array,
                        numSupport: array.length,
                        actualize: true
                    }, () => {
                        this.setState({
                            actualize: false
                        })
                    }) 
                }
                
            }
        }
    }
    render() {
        return (
            
            <div className="containerZipFolder">
                {(this.state.isLoading) ? <Loadering /> : null}
                <div className="containerBg">
                    <div className="containerZipper">
                        <div className="headerDiv">
                            <img src="img/gearKit.png"></img>
                            <h3>Générer vos kits emailing</h3>
                        </div>
                        <div className="containerForm">
                            <div className="containerChild">
                                <ZipComponents numSupport={this.state.numSupport} actualize={this.state.actualize} datas={this.state.data} callBackFromComps={this.callBack}></ZipComponents>
                            </div>
                            <div className="containerButtons">
                                <input
                                    type="submit"
                                    onClick={this.addSupport}
                                    className="btn btn-primary justify-content-center d-flex btnChild"
                                    value="Ajouter un support"
                                />
                                <input
                                    type="submit"
                                    onClick={this.removeSupport}
                                    className="btn btn-primary justify-content-center d-flex ml-5 btnChild"
                                    value="Retirer un support"
                                />
                            </div>
                        </div>
                        <div className="footerZipper">
                            <div className="back" onClick={this.redirectCancel}>
                                <img src="img/back.png"></img>
                                <p>Retour</p>
                            </div>
                            <div className="actionZip" onClick={this.zipFunction}>
                                <p>Télécharger</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default ZipFolder