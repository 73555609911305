import React, { Component } from 'react';

class LabelDash extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="rowListCopy headerProjectList" style={{ display: this.props.displayLabel}}>
                <div className="numJob emptyJob"></div>
                <div className="col1">
                    Nom
                </div>
                <div className="col1">
                    Promoteur
                </div>
                <div className="col1">
                    <p class="label">Statut</p>
                </div>
                <div className="col1">
                    Dates
                </div>
                <div className="col1"></div>
            </div>
        )
    }
}

export default LabelDash