import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import access from './Access';

const cookies = new Cookies();
class Login extends Component {
    state = {
        username: '',
        email: '',
        password: '',
        errors: {}
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            errors: {}
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        const newUser = {
            email: this.state.email,
            password: this.state.password,
        };
        
        axios
            .post('/api/users/login', newUser)
            .then(res => {
                localStorage.setItem('usertoken', res.data)
                if(access.logall() === true) {
                    this.props.history.push('/dashboard', { _id: res.data._id })
                }
            })
            .catch(err => {
                this.setState({
                    errors: err.response.data
                }) 
              })
    };

    render() {
        const { username, email, password, errors } = this.state;
        return (
            <React.Fragment>
                <div className="containerLogin">
                    <div className="containerContent">
                        <h1>Emailing Manager</h1>
                        <div className="containerForm">
                            <div className="leftForm">
                                <img src="img/imgLogin.png"></img>
                            </div>
                            <div className="rightForm">
                                <form onSubmit={this.handleSubmit}>
                                <div className="containerChamps">
                                        <div className="mt10 divLogin">
                                            <input 
                                                id="input-email"
                                                type="text" 
                                                name="email"
                                                value={email}
                                                onChange={this.handleChange}
                                                className={
                                                    errors.type === "email" 
                                                    ? 'is-invalid question' 
                                                    : 'question'
                                                }
                                                required 
                                                autoComplete="off"
                                            />
                                            <label for="input-email"><span>Email</span><img src="./img/doneIcon.png"></img></label>
                                            <span className="invalid-feedback">{errors.msg}</span>
                                        </div>
                                        <div className="mt10">
                                            <input 
                                                id="input-pwd"
                                                type="password" 
                                                name="password"
                                                value={password}
                                                onChange={this.handleChange}
                                                className={
                                                    errors.type === "password" 
                                                    ? 'is-invalid question' 
                                                    : 'question'
                                                }
                                                required 
                                                autoComplete="off"
                                            />
                                            <label for="input-pwd"><span>Mot de passe</span><img src="./img/doneIcon.png"></img></label>
                                            <span className="invalid-feedback">{errors.msg}</span>
                                        </div>
                                    </div>
                                    
                                    <input
                                        type="submit"
                                        className="btn btn-primary justify-content-center d-flex w-50 connexionButton"
                                        value="Connexion"
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Login