import React, { Component } from 'react';
import Partial from './Partial';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Partials extends Component {
    constructor(props){
        super(props);
        this.state = {
            partialsListType: [],
            type: this.props.modele,
            selectedPartials: ''
        }
    }
    async componentDidMount(){
        let responseGet = await fetch(`/api/projects/${cookies.get("projectId")}`);
        let resultGet = await responseGet.json();
        this.setState({
            selectedPartials: resultGet.data.type
        })
        
        this.getPartialsType();
    }
    getPartialsType = async () => {
        let response = await fetch("/api/directory/getPartials", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                type: this.state.selectedPartials
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            this.setState({
                partialsListType: result
            },()=>{
                // console.log(this.state.partialsListType);
                
            })
        }
    }


    render() {
        let partials = this.state.partialsListType.map(elem =>
                <Partial
                    key={elem.id}
                    name={elem}
                    type={this.state.selectedPartials}
                ></Partial>
            );
        return (
                <div>
                    {partials}
                </div>
        )
    }
}

export default Partials