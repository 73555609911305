import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
class CreateFolder extends Component {
    state = {
        programme: '',
        promoteur: '',
        ville:'',
        errors: {}
    };

    

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

 /*   createProject = async() => {

    };*/

    handleSubmit = async e => {
        e.preventDefault();
        var programme = this.state.programme;
        var promoteur = this.state.promoteur;
        var d = new Date();
        var date = d.getDate().toString();
        var month = d.getMonth() + 1;
        var currentMonth = month.toString();
        var year = d.getFullYear().toString();
        var full = date + currentMonth + year;
        let projectName = programme + '_' + promoteur;
        var folderName = full + '_' + programme + '_' + promoteur;
        // console.log(folderName);
        
        const create = {
            username: cookies.get("username"),
            userId: cookies.get("userId"),
            folder: folderName,
            projectName: projectName,
            programme: programme,
            promoteur: promoteur
        };
        // console.log(create);
     
        await this.createFolder(create);
        await this.createProject(create);
    };
    
    createFolder = async(create) => {
        axios
        .post('/api/directory/createFolder', create)
        .then(res => this.props.history.push('/copy', { username: create.username, folderPath: create.folder })            )
        .catch(err => 
            this.setState({
                errors: err.response.data
            })
        );
    }

    createProject = async (create) => {
        let response = await fetch("/api/projects/create",
        {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                userId: create.userId,
                ville: this.state.ville,
                campagne: create.programme,
                promoteur: create.promoteur,
                folder: create.folder
            })
        }
        );
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
            cookies.set("projectId", result.data.id);
        }
    }

    render() {
        const { programme, ville, promoteur, errors } = this.state;
        return (
            <React.Fragment>
                <div className="container">
                    <div className="ContainerCreateFolder">
                        <div className="mt-5 text-center mb-0">
                            <h2 className="mt-5 text-center mb-0">Créer un nouvel email</h2>
                            <p className="text-lead text-center">Entrer les informations pour continuer</p>
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        name="programme"
                                        value={programme}
                                        onChange={this.handleChange}
                                        className={
                                            errors.type === "error" 
                                            ? 'is-invalid form-control' 
                                            : 'form-control'
                                        }
                                        placeholder="Programme"
                                    />
                                    <span className="invalid-feedback">{errors.msg}</span>
                                </div>
                                <div className="form-group">
                                    <input 
                                        type="text"
                                        name="promoteur"
                                        value={promoteur}
                                        onChange={this.handleChange}
                                        className={
                                            errors.type === "error" 
                                            ? 'is-invalid form-control' 
                                            : 'form-control'
                                        }
                                        placeholder="Promoteur"
                                    />
                                    <span className="invalid-feedback">{errors.msg}</span>
                                </div>
                                <div className="form-group champsVille">
                                    <input 
                                        className="form-control"
                                        type="text"
                                        name="ville"
                                        value={ville}
                                        onChange={this.handleChange}
                                        placeholder="ville"
                                    />
                                </div>

                                <input
                                        type="submit"
                                        className="btn btn-primary justify-content-center d-flex w-50 createButton"
                                        value="Create"
                                    />
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CreateFolder