import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import ZipComponent from './ZipComponent';
const cookies = new Cookies();

class ZipComponents extends Component {
    constructor(props){
        super(props);
        this.state = {
            support: []
        }
    }
    callBackChild = (dataFromChild) => {
        this.setState({
            support: dataFromChild
        }, () => {
            console.log('ZipComponents : html : ', this.state.support.html);
            this.props.callBackFromComps(this.state.support)
        })
    }
    render() {
        const children = [];
        
        for (var i = 0; i < this.props.numSupport; i += 1) {
            this.props.actualize ? 
            children.push(<ZipComponent callBackFromParent={this.callBackChild} actualize={this.props.actualize} datas={this.props.datas[i]} key={i} />)
            :
            children.push(<ZipComponent callBackFromParent={this.callBackChild} actualize={this.props.actualize} datas={this.props.datas[i]} key={i} id={Math.random(Math.random(50))} />)
          };
        return (
            <div className="ajustZipPadding">
                {children}
            </div>
        )
    }
}

export default ZipComponents