import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import Loader from 'react-loader-spinner';
const cookies = new Cookies();
const Loadering = () => <div className="containerLoading">
                            <div
                                    className="loading"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                <Loader type="ThreeDots" color="#2BAD60" />
                            </div>
                        </div>;
class Project extends Component {
    constructor(props){
        super(props);
        this.dotsin = this.dotsin.bind(this)
        this.handleMouseMove = this.handleMouseMove.bind(this)
        this.dotsout = this.dotsout.bind(this)
    }
    state = {
        statePopIn: false,
        stateDots: false,
        display: "none",
        dots: "none",
        displayDuplicate: "none",
        programme: '',
        promoteur: '',
        ville:'',
        job: '####',
        displayBat: 'none',
        displayConfirm: 'none',
        isLoading: false,
        colorBorder: "",
        couleurCreator: '',
        couleurUpdator: '',
        formatDate: '',
        formatDateCre: '',
        displayScreen: 'none',
        folderReg: '',
        element: null,
        width: null,
        height: null,
        left: null,
        top: null,
        transitionTimeout: null,
        updateCall: null,
        rightIframe: 0,
        topIframe: '45px',
        settings: {
            max: 35,
            perspective: 1000,
            easing: 'cubic-bezier(.03,.98,.52,.99)',
            scale: '1.1',
            speed: '1000',
            transition: true,
            axis: null,
            reset: true
        }
    };
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    /** Accéder à un projet */
    archiver = async e => {
        e.preventDefault();
        const content = {
            username: this.props.userCreator,
            userUpdator:  cookies.get("username"),
            folder: this.props.folder,
            projId: this.props.id,
            status: this.props.status
        }; 
        // await this.capture(content);
        await this.updateDb(content);
        
        // console.log('reload');
        
        window.location.reload(true);
    }
    capture = async (content) => {
        if(content.status === 'Deploiement effectué' || content.status === 'Build effectué' || content.status === 'BAT client' || content.status === 'BAT interne') {
            await this.createFolderScreen(content);
            await this.takeScreen(content);
            await this.deployScreenOnFtp(content);
        }
    }
    createFolderScreen = async (content) => {
        let response = await fetch("/api/gulpRoute/createFolder", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.username,
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    deployScreenOnFtp = async (content) => {
        let response = await fetch("/api/gulpRoute/deployScreenOnFtp", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.username,
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    takeScreen = async (content) => {
        let response = await fetch("/api/gulpRoute/puppeteer", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.username,
                url: `https://www.emailingmanager-360.net/api/server/html/${content.username}/${content.folder}`
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    updateDb = async (content) =>{
        let response = await fetch(`/api/projects/${content.projId}`, {
            headers : {
                "Content-Type": "application/json"
            },
            method: "PATCH",
            body: JSON.stringify({
                statusFictif: "Archive",
                userUpdator: content.userUpdator
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    selectProj = () => {
        const content = {
            userCreator: this.props.userCreator,
            userUpdator: this.props.userUpdator,
            folder: this.props.folder,
            status: this.props.status,
            statusFictif: this.props.statusFictif,
            type: this.props.type,
            promoteur: this.props.promoteur,
            programme: this.props.campagne,
            ville: this.props.ville,
            job: this.props.job,
            id: this.props.id
        }
        
        if(content.status === 'Deploiement effectué' && content.statusFictif !== 'Archive') {
            this.redirectTest(content)
            cookies.set("projectId", content.id)
            cookies.set("type", content.type)
        }
        if(content.status === 'Archive' || content.statusFictif === 'Archive' && content.status !== 'En conception' && content.status !== 'Build effectué') {
            // console.log('Access to Archives');
            this.showHtmlView(content)
        }
        if(content.status === 'Build effectué' && content.statusFictif !== 'Archive') {
            this.redirectContent(content)
            cookies.set("projectId", content.id)
            cookies.set("type", content.type)
        }
        if(content.status === 'BAT client' && content.statusFictif !== 'Archive') {
            this.redirectTest(content)
            cookies.set("projectId", content.id)
            cookies.set("type", content.type)
        }
        if(content.status === 'BAT interne' && content.statusFictif !== 'Archive') {
            this.redirectTest(content)
            cookies.set("projectId", content.id)
            cookies.set("type", content.type)
        }
        if(content.status === 'En conception' && content.statusFictif !== 'Archive') {
            this.redirectBuild(content)
            cookies.set("projectId", content.id)
            cookies.set("type", content.type)
        }
    }
    showHtmlView = async (content) => {
        await window.open(`https://www.emailingmanager-360.net/api/server/html/${content.userCreator}/${content.folder}`, "_blank");
        // await window.open(`http://emailingmanager-360.net/:5000/api/directory/getURL/user/${build.username}/project/${build.folderDest}/html/index.html`, "_blank");
    }
    redirectTest = (content) => {
        cookies.set("projectId", this.props.id);
        cookies.set("type", this.props.type);
        return this.props.history.push('/test', { userCreator: content.userCreator, folderPath: content.folder, promoteur: content.promoteur, programme: content.programme, ville: content.ville, jobNumber: content.job, status: this.props.status })
    }
    redirectContent = (content) => {
        cookies.set("projectId", this.props.id);
        cookies.set("type", this.props.type);
        return this.props.history.push('/content', { userCreator: content.userCreator, folderPath: content.folder, promoteur: content.promoteur, programme: content.programme, ville: content.ville, jobNumber: content.job, status: this.props.status })
    }
    redirectBuild = (content) => {
        cookies.set("projectId", this.props.id);
        cookies.set("type", this.props.type);
        return this.props.history.push('/build', { userCreator: content.userCreator, type:content.type, folderPath: content.folder, promoteur: content.promoteur, programme: content.programme, ville: content.ville, jobNumber: content.job, status: this.props.status })
    }
    /** Suppression d'un email */
    confirmDelete = () => {
        this.setState({
            displayConfirm: 'block'
        });
    }
    delete = async e => {
        e.preventDefault();
        const content = {
            id: this.props.id,
            username: this.props.userCreator,
            folder: this.props.folder
        }
        await this.deleteDb(content);
        await this.deleteFolder(content);
        this.setState({
            displayConfirm: 'none'
        })
        window.location.reload(true);
    }
    deleteDb = async (content) =>{
        let response = await fetch(`/api/projects/${content.id}`, {
            headers : {
                "Content-Type": "application/json"
            },
            method: "DELETE"
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    deleteFolder = async (content) =>{
        let response = await fetch("/api/directory/deleteFolder", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.username
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
        return;
    }
    /** Gestion affichage menu email */
    popout = () => {
        this.setState({
            display: 'none'
        });
    }
    popin = () => {
        this.setState({
            display: 'block'
        });
    }
    cb = () => { }
    handleMouseMove = e => {
        e.persist();
        if (this.state.updateCall !== null) {
          window.cancelAnimationFrame(this.state.updateCall)
        }
        const rect = this.state.element.getBoundingClientRect();
        // console.log(rect);
        // console.log(this.state.element);
        this.event = e
        this.setState({
            top: e.clientY - rect.top,
            left: e.clientX - rect.left,
            updateCall: requestAnimationFrame(this.update.bind(this, e))
        })
        return this.cb(e)
    }

    dotsin = e => {
        // console.log('onMouseLeave', e.clientX, e.clientY)
        this.setState({
            dots: 'block'
        });
        if(this.props.statusFictif === 'Archive') {
            if(this.props.status === 'Deploiement effectué' || this.props.status === 'Build effectué' || this.props.status === 'BAT client' || this.props.status === 'BAT interne') {
                this.setState({
                    displayScreen: 'block'
                })
            }
        }
        this.updateElementPosition()
    }
    dotsout = e => {
        // console.log('onMouseLeave', e.clientX, e.clientY)
        this.setState({
            dots: 'none',
            displayScreen: 'none'
        });
    }
    adjustPosition = e => {
        var waterLine = window.innerHeight;
        if(waterLine - e.clientY < 400) {
            this.setState({
                topIframe: -(400 - ( waterLine - e.clientY )) + 'px',
                rightIframe: '45px'
            })
        } else {
            this.setState({
                topIframe: '45px',
                rightIframe: 0
            })
        }
    }
    updateElementPosition() {
        const rect = this.state.element.getBoundingClientRect()
        this.setState({
            width: this.state.element.offsetWidth,
            height: this.state.element.offsetHeight,
            left: rect.left,
            top: rect.top
        })
        // console.log('REKT', rect)
        // console.log('OFFSET WIDTH', this.state.element.offsetWidth)
        // console.log('OFFSET HEIGHT', this.state.element.offsetHeight)
      }
    getValues = e => {
        // console.log(e.clientX);
        const x = (e.clientX - this.state.left) / this.state.width
        const y = (e.clientY - this.state.top) / this.state.height
        const _x = Math.min(Math.max(x, 0), 1)
        const _y = Math.min(Math.max(y, 0), 1)
        const tiltX = ((this.state.settings.max / 2 - _x * this.state.settings.max)).toFixed(2)
        const tiltY = ((_y * this.state.settings.max - this.state.settings.max / 2)).toFixed(2)
        const percentageX = _x * 100
        const percentageY = _y * 100
        // console.log('JUST GOT NEW VALUES', `X: ${x} Y: ${y} -- TILT X: ${tiltX} TILT Y: ${tiltY} -- TILT X%: ${percentageX} TILT Y%: ${percentageY}`)
        // console.log('Notice how X turned into percentageX.')
        return {
          tiltX,
          tiltY,
          percentageX,
          percentageY,
        }
    }
    update(e) {
        let values = this.getValues(e)
        // console.log('VALUES', values)
        //console.log('NEW CSS TRANSFORM VALUES', `perspective(${this.settings.perspective}px) rotateX(${this.settings.axis === 'x' ? 0 : values.tiltY}deg) rotateY(${this.settings.axis === 'y' ? 0 : values.tiltX}deg) scale3d(${this.settings.scale}, ${this.settings.scale}, ${this.settings.scale})`)
        this.setState({
            updateCall: null
        })
      }
    /** Dupliquer un email */
   duplicate = () => {
        this.setState({
            displayDuplicate: 'block'
        });
   }
   closeDuplicate = () => {
        this.setState({
            displayDuplicate: 'none',
            displayConfirm: 'none'
        });
   }
   handleSubmit = async e => {
        this.setState({
            isLoading: true
        })
        e.preventDefault();
        var programme = this.state.programme;
        var promoteur = this.state.promoteur;
        var ville = this.state.ville;
        var job = this.state.job;
        var d = new Date();
        var month = d.getMonth();
        month = (month + 1).toString();
        if (month.length === 1){ 
            month = "0" + month;
        }
        var year = d.getFullYear().toString().substr(-2);
        var full = year + month;
        var folderName = full + '_' + programme + '_' + promoteur;
        // console.log(folderName);
        
        const create = {
            username: cookies.get("username"),
            userId: cookies.get("userId"),
            userCreator: this.props.userCreator,
            folder: folderName,
            programme: programme,
            promoteur: promoteur,
            job: job,
            ville: ville,
            folderSrc: this.props.folder,
            oldTitre: this.props.promoteur,
            id: this.props.id,
            status: this.props.status
        };
        if(create.status === 'Deploiement effectué') {
            cookies.set("projectId", create.id)
            cookies.set("type", this.props.type);
            await this.createFolder(create);
            await this.createProjectDep(create);
            await this.copy(create);
            await this.changeDataDuplicate(create);
            await this.replaceDuplicateLinks(create); 
            await this.deploy(create);
            await this.redirectTestDup(create);
        }
        if(create.status === 'Archive') {
            cookies.set("projectId", create.id)
            cookies.set("type", this.props.type);
            await this.createFolder(create);
            await this.createProjectDep(create);
            await this.copy(create);
            await this.changeDataDuplicate(create);
            await this.replaceDuplicateLinks(create); 
            await this.deploy(create);
            await this.redirectTestDup(create);
        }
        if(create.status === 'Build effectué') {
            cookies.set("projectId", create.id)
            cookies.set("type", this.props.type);
            await this.createFolder(create);
            await this.createProjectBuild(create);
            await this.copy(create);
            await this.changeDataDuplicate(create);
            await this.replaceDuplicateLinks(create); 
            await this.deploy(create);
            await this.redirectContentDup(create);
        }
        if(create.status === 'BAT client') {
            cookies.set("projectId", create.id)
            cookies.set("type", this.props.type);
            await this.createFolder(create);
            await this.createProjectDep(create);
            await this.copy(create);
            await this.changeDataDuplicate(create);
            await this.replaceDuplicateLinks(create); 
            await this.deploy(create);
            await this.redirectTestDup(create);
        }
        if(create.status === 'BAT interne') {
            cookies.set("projectId", create.id)
            cookies.set("type", this.props.type);
            await this.createFolder(create);
            await this.createProjectDep(create);
            await this.copy(create);
            await this.changeDataDuplicate(create);
            await this.replaceDuplicateLinks(create); 
            await this.deploy(create);
            await this.redirectTestDup(create);
        }
        if(create.status === 'En conception') {
            cookies.set("projectId", create.id)
            cookies.set("type", this.props.type);
            await this.createFolder(create);
            await this.createProjectBeg(create);
            await this.copy(create);
            await this.redirectBuildDup(create);
        }

        // console.log(create);
        
        this.setState({
            isLoading: false
        })
        
    };
    deploy = async (create) => {
        // console.log(create);
        let response = await fetch("/api/gulpRoute/deployBuildOnFtp", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: this.state.folderReg,
                username: create.username,
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    createFolder = async (create) => {
        let response = await fetch("/api/directory/createFolder", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: create.username,
                folder: create.folder
            })
        })
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            this.setState({
                folderReg: result.dossier
            }, () => {
                console.log(this.state.folderReg);
            })
            console.log(result);
        }
        return;
    }
    createProjectDep = async (create) => {
        let response = await fetch("/api/projects/create",
        {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                userId: create.userId,
                ville: create.ville,
                job: create.job,
                type: this.props.type,
                campagne: create.programme,
                promoteur: create.promoteur,
                folder: this.state.folderReg,
                userCreator: create.username,
                userUpdator: create.username,
                status: 'Deploiement effectué',
                statusFictif: 'En conception',
                url: null,
                mailBat: null,
                mailInterne: null,
                support: null
            })
        }
        );
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
            cookies.set("type", this.props.type);
            cookies.set("projectId", result.data.id);
        }
    }
    createProjectBuild = async (create) => {
        let response = await fetch("/api/projects/create",
        {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                userId: create.userId,
                ville: create.ville,
                job: create.job,
                type: this.props.type,
                campagne: create.programme,
                promoteur: create.promoteur,
                folder: create.folder,
                userCreator: create.username,
                userUpdator: create.username,
                status: 'Build effectué',
                statusFictif: 'En conception',
                url: null,
                mailBat: null,
                mailInterne: null,
                support: null
            })
        }
        );
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
            cookies.set("projectId", result.data.id);
            cookies.set("type", this.props.type);
        }
    }
    createProjectBeg = async (create) => {
        let response = await fetch("/api/projects/create",
        {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                userId: create.userId,
                ville: create.ville,
                job: create.job,
                type: this.props.type,
                campagne: create.programme,
                promoteur: create.promoteur,
                folder: this.state.folderReg,
                userCreator: create.username,
                userUpdator: create.username,
                status: 'En conception',
                statusFictif: 'En conception',
                url: null,
                mailBat: null,
                mailInterne: null,
                support: null
            })
        }
        );
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
            cookies.set("projectId", result.data.id);
        }
    }
    createProject = async (create) => {
        let response = await fetch("/api/projects/create",
        {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                userId: create.userId,
                ville: create.ville,
                job: create.job,
                type: this.props.type,
                campagne: create.programme,
                promoteur: create.promoteur,
                folder: create.folder,
                userCreator: create.username,
                userUpdator: create.username,
                status: this.props.status,
                statusFictif: this.props.statusFictif,
                url: null,
                mailBat: null,
                mailInterne: null,
                support: null
            })
        }
        );
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
            cookies.set("projectId", result.data.id);
            cookies.set("type", this.props.type);
        }
    }
    copy = async (create) => {
        let response = await fetch("/api/directory/recursiveCopyExist",
        {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                userCreator: create.userCreator,
                username: create.username,
                folder: create.folderSrc,
                folderDest: this.state.folderReg
            })
        }
        );
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    changeDataDuplicate = async (create) => {
        let response = await fetch("/api/gulpRoute/changeDataDuplicate",
        {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                promoteur: create.promoteur,
                username: create.username,
                folderDest: this.state.folderReg,
                titre: create.promoteur,
                oldTitre: create.oldTitre,
                lien: '##########'
            })
        }
        );
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    replaceDuplicateLinks = async (create) => {
        let response = await fetch("/api/gulpRoute/replaceDuplicateLinks",
        {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: create.username,
                userSrc: this.props.userCreator,
                folder: this.state.folderReg,
                folderSrc: create.folderSrc
            })
        }
        );
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    redirectContentDup = (create) => {
        return this.props.history.push('/content', { userCreator: create.username, type:this.props.type, folderPath: this.state.folderReg, promoteur: create.promoteur, programme: create.programme, ville: create.ville, jobNumber: create.job, status: this.props.status })
    }
    redirectBuildDup = (create) => {
        return this.props.history.push('/build', { userCreator: create.username, type:this.props.type, folderPath: this.state.folderReg, promoteur: create.promoteur, programme: create.programme, ville: create.ville, jobNumber: create.job, status: this.props.status })
    }
    redirectTestDup = (create) => {
        console.log('Redirection vers page test');
        return this.props.history.push('/test', { userCreator: create.username, type:this.props.type, folderPath: this.state.folderReg, promoteur: create.promoteur, programme: create.programme, ville: create.ville, jobNumber: create.job, status: this.props.status })
    }
    
    /** Au chargement de la page */
    async componentDidMount(){
        this.setState({
            element: findDOMNode(this),
            promoteur: this.props.promoteur,
            programme: this.props.campagne,
            ville: this.props.ville
        })
        let formatDateCre = this.props.createdAt.split('T')[0].split('-').reverse();
        let monthDateCre = formatDateCre[1];
        if(monthDateCre == '01'){
            formatDateCre[1] = 'jan'
        }
        if(monthDateCre == '02'){
            formatDateCre[1] = 'fev'
        }
        if(monthDateCre == '03'){
            formatDateCre[1] = 'mar'
        }
        if(monthDateCre == '04'){
            formatDateCre[1] = 'avr'
        }
        if(monthDateCre == '05'){
            formatDateCre[1] = 'mai'
        }
        if(monthDateCre == '06'){
            formatDateCre[1] = 'juin'
        }
        if(monthDateCre == '07'){
            formatDateCre[1] = 'juil'
        }
        if(monthDateCre == '08'){
            formatDateCre[1] = 'aou'
        }
        if(monthDateCre == '09'){
            formatDateCre[1] = 'sep'
        }
        if(monthDateCre == '10'){
            formatDateCre[1] = 'oct'
        }
        if(monthDateCre == '11'){
            formatDateCre[1] = 'nov'
        }
        if(monthDateCre == '12'){
            formatDateCre[1] = 'dev'
        }

        let formatDate = this.props.updatedAt.split('T')[0].split('-').reverse();
        let monthDate = formatDate[1];
        if(monthDate == '01'){
            formatDate[1] = 'jan'
        }
        if(monthDate == '02'){
            formatDate[1] = 'fev'
        }
        if(monthDate == '03'){
            formatDate[1] = 'mar'
        }
        if(monthDate == '04'){
            formatDate[1] = 'avr'
        }
        if(monthDate == '05'){
            formatDate[1] = 'mai'
        }
        if(monthDate == '06'){
            formatDate[1] = 'juin'
        }
        if(monthDate == '07'){
            formatDate[1] = 'juil'
        }
        if(monthDate == '08'){
            formatDate[1] = 'aou'
        }
        if(monthDate == '09'){
            formatDate[1] = 'sep'
        }
        if(monthDate == '10'){
            formatDate[1] = 'oct'
        }
        if(monthDate == '11'){
            formatDate[1] = 'nov'
        }
        if(monthDate == '12'){
            formatDate[1] = 'dev'
        }
        let goodFormatDate = formatDate.join(' ');
        let goodFormatDateCre = formatDateCre.join(' ');

        if(this.props.bat !== 0 && this.props.status === "BAT client") {
            this.setState({
                colorBorder: "block"
            })
        }
        if(this.props.statusFictif === 'BAT client') {
            this.setState({
                colorBorder: "10px solid #33225D",
                displayBat: "flex"
            })
        }
        if(this.props.statusFictif === 'BAT interne') {
            this.setState({
                colorBorder: "10px solid #ff4c2d"
            }) 
        }
        if(this.props.statusFictif === 'En conception') {
            this.setState({
                colorBorder: "10px solid #ff9f2d"
            })
        }
        if(this.props.statusFictif === 'Archive') {
            this.setState({
                colorBorder: "10px solid #6FC30E"
            }) 
        }
        let pinColorCreator = await fetch(`/api/users/user/${this.props.userCreator}`);
        let pinColorUpdator = await fetch(`/api/users/user/${this.props.userUpdator}`);        
        let resultColorCreator = await pinColorCreator.json();
        let resultColorUpdator = await pinColorUpdator.json();
        // console.log(resultColorCreator.data.color);
        
        this.setState({
            urlPreview: `https://www.emailingmanager-360.net/api/server/html/${this.props.userCreator}/${this.props.folder}`,
            couleurCreator: resultColorCreator.data.color,
            couleurUpdator: resultColorUpdator.data.color,
            formatDate: goodFormatDate,
            formatDateCre: goodFormatDateCre
        })
    }
    render() {
        let getClass = 'statut';
        let menuAll = '';
        let menuArch = 'display: none;';
        if(this.props.statusFictif === 'BAT client') {
            getClass += ' batDone' 
        }
        if(this.props.statusFictif === 'BAT interne') {
            getClass += ' interneDone' 
        }
        if(this.props.statusFictif === 'En conception') {
            getClass += ' conception' 
        }
        if(this.props.statusFictif === 'Archive') {
            getClass += ' archive' 
            menuArch = 'display: block;'
            menuAll = 'display: none;'
        }
        return (
            <div className="rowListCopy" style={{borderLeft: this.state.colorBorder}}  onMouseEnter={this.dotsin} onMouseLeave={this.dotsout} onMouseMove={this.handleMouseMove}>
                    {(this.state.isLoading) ? <Loadering /> : null}
                    {/*<div className="previewScreenArchive" style={{display:this.state.displayScreen, top: this.state.top, left: this.state.left}}>
                        <img src={`http://www.3cent60.biz/emailing/bat/emailingmanager/${this.props.userCreator}/${this.props.folder}/screen/image.jpg`}/>
                    </div>*/}
                    <div className="duplicatePopin" style={{display:this.state.displayConfirm}}>
                    <div className="bgDuplicatePop">
                        <div className="containerConfirmPop">
                            <div className="headerPop">
                                <img src="img/letterCreation.png"></img>
                                <h2>Confirmer la suppression de l'emailing</h2>
                            </div>
                            <div className="supprPara">
                                <p>Promoteur : </p><span>{this.props.promoteur}</span>
                            </div>
                            <div className="supprPara">
                                <p>Campagne : </p><span>{this.props.campagne}</span>
                            </div>
                            <div className="supprPara">
                                <p>Ville : </p><span>{this.props.ville}</span>
                            </div>
                            <form onSubmit={this.delete}>
                                <div className="containerButtons">
                                    <div 
                                        onClick={this.closeDuplicate}
                                        className="retourDash"    
                                    >
                                        <img src="img/back.png"></img>
                                        <p>Retour au Dashboard</p>
                                    </div>
                                    <input
                                        type="submit"
                                        className="btn btn-primary justify-content-center d-flex"
                                        value="Supprimer"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                    <div className="duplicatePopin" style={{display:this.state.displayDuplicate}}>
                    <div className="bgDuplicatePop">
                        <div className="containerDuplicatePop">
                            <div className="headerPop">
                                <img src="img/letterCreation.png"></img>
                                <h2>Dupliquer un emailing</h2>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="formsPop">
                                    <div className="inputWithIcon">
                                        <input
                                            id="input-job"
                                            type="number"
                                            className="question"
                                            name="job"
                                            value={this.state.job}
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                        />
                                        <label for="input-job"><span>Job</span><img src="./img/doneIcon.png"></img></label>
                                        <img src="img/ic-id.png"></img>
                                    </div>
                                    <div className="inputWithIcon">
                                        <input
                                            id="input-promoteur"
                                            className="question"
                                            type="text"
                                            name="promoteur"
                                            value={this.state.promoteur}
                                            onChange={this.handleChange}
                                            required 
                                            autoComplete="off"
                                        />
                                        <label for="input-promoteur"><span>Promoteur</span><img src="./img/doneIcon.png"></img></label>
                                        <img src="img/ic-prom-2.png"></img>
                                    </div>
                                    <div className="inputWithIcon">
                                        <input
                                            id="input-programme"
                                            className="question"
                                            type="text"
                                            name="programme"
                                            value={this.state.programme}
                                            onChange={this.handleChange}
                                            required 
                                            autoComplete="off"
                                        />
                                        <label for="input-programme"><span>Nom du projet</span><img src="./img/doneIcon.png"></img></label>
                                        <img src="img/prog.png"></img>
                                    </div>
                                    <div className="inputWithIcon">
                                        <input
                                            id = "input-ville"
                                            className="question"
                                            type="text"
                                            name="ville"
                                            value={this.state.ville}
                                            onChange={this.handleChange}
                                            required 
                                            autoComplete="off"
                                        />
                                        <label for="input-ville"><span>Ville</span><img src="./img/doneIcon.png"></img></label>
                                        <img src="img/ville.png"></img>
                                    </div>
                                </div>
                                <div className="containerButtons">
                                    <div 
                                        onClick={this.closeDuplicate}
                                        className="retourDash"    
                                    >
                                        <img src="img/back.png"></img>
                                        <p>Retour au Dashboard</p>
                                    </div>
                                    <input
                                        type="submit"
                                        className="btn btn-primary justify-content-center d-flex"
                                        value="Dupliquer"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                    <div className="groupColDash" onClick={this.selectProj}>
                        <div className="numJob">
                            <img src="./img/letterDash.png"></img>
                            {
                                this.props.job === '####' ? 
                                <p style={{color: "#c40d00"}}>#{this.props.job}</p>
                                :
                                <p style={{color: "#353535"}}>#{this.props.job}</p>
                            }
                            
                        </div>
                        <div className="col1">
                            <div>
                                <div className="prog">
                                    {this.props.campagne}
                                </div>
                                <div className="ville">
                                    {this.props.ville}
                                </div>
                            </div>
                        </div>               
                        <div className="col1">
                            <div className="promoteur">
                                {this.props.promoteur}
                            </div>
                        </div>
                        <div className="col1">
                            <div className={getClass}>
                                {this.props.statusFictif}
                                <div style={{display:this.state.displayBat}}><p>{this.props.bat}</p></div>
                            </div>
                        </div>
                        <div className="col1">
                            <div className="date">
                                <div className="topDate">
                                    <img src="./img/icon-datecreation.svg"></img>
                                    {this.state.formatDateCre}
                                </div>
                                <div className="botDate">
                                    <div className="pinColor" style={{backgroundColor:this.state.couleurCreator}}></div>
                                    <p>Par {this.props.userCreator}</p>
                                </div>                        
                            </div>
                        </div>
                        <div className="col1">
                            <div className="date">
                                <div className="topDate">
                                    <img src="./img/icon-datemaj.svg"></img>
                                    {this.state.formatDate}
                                </div>
                                <div className="botDate">
                                    <div className="pinColor" style={{backgroundColor:this.state.couleurUpdator}}></div>
                                    <p>Par {this.props.userUpdator}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="showOptions">
                        <div onMouseEnter={this.adjustPosition}>
                            <object type="image/svg+xml" data="./img/point-eye.svg" width="16" height="16"></object>
                            <div class="sousnav sousNavArch contIframe" style={{top:this.state.topIframe, right:this.state.rightIframe}}>
                                <iframe id="inlineFrameExample" src={this.state.urlPreview} width="550"></iframe>
                            </div>
                        </div>
                    </div>
                    {this.props.statusFictif === 'Archive' ? 
                        <div className="options">
                            <div>
                                <object type="image/svg+xml" data="./img/icon-more-options.svg" width="16" height="16"></object>
                                <div class="sousnav sousNavArch">
                                    <div class="list">
                                            <ol>
                                                <li onClick={this.duplicate} >Nouveau projet</li>
                                                <li onClick={this.confirmDelete} >Supprimer</li>
                                            </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="options">
                            <div>
                                <object type="image/svg+xml" data="./img/icon-more-options.svg" width="16" height="16"></object>
                                <div class="sousnav">
                                    <div class="list">
                                        <ol>
                                            <li onClick={this.duplicate} >Dupliquer</li>
                                            <li onClick={this.archiver} >Archiver</li>
                                            <li onClick={this.confirmDelete} >Supprimer</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                                
                    }
            </div>
        )
    }
}

export default Project