import React, { Component } from 'react';
import axios from 'axios';

class Cleaner extends Component {

    handleSubmit = e => {
        e.preventDefault();
        
        const datas = {
            username: this.props.location.state.username,
            folder: this.props.location.state.folderPath,
            type: this.props.location.state.type,
        };

        axios
            .post('/api/gulpRoute/cleanImg', datas)
            .then(res => this.props.history.push('/content', { username: datas.username, folderPath: datas.folder, type: datas.type })); 
    };

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="mt-5 text-center mb-0">
                            <h2 className="mt-5 text-center mb-0">Clean your directory</h2>
                            <form onSubmit={this.handleSubmit}>
                                <input
                                    type="submit"
                                    className="btn btn-primary justify-content-center d-flex w-100"
                                    value="Clean"
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Cleaner