import React, { Component } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

class Copy extends Component {
    state = {
        selectedOption: '',
        listChoice: ''
    };

    handleOptionChange = e => {
        this.setState({
          selectedOption: e.target.value
        });
    };

    handleOptionChangeList = e => {
        this.setState({
            listChoice: e.target.value
        });
    };

    handleSubmit = async e => {
        console.log(
            this.props.location.state.username,
            this.props.location.state.folderPath,
        );
        
        e.preventDefault();
        const copy = {
            username: this.props.location.state.username,
            folderDest: this.props.location.state.folderPath,
            type: this.state.selectedOption,
            projId: cookies.get("projectId")
        };
        await this.copy(copy);
        await this.updateDb(copy);
        this.redirect(copy);
    };
    copy = async (copy) => {
        let response = await fetch("/api/directory/recursiveCopy", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folderDest: copy.folderDest,
                username: copy.username,
                type: copy.type
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            console.log(result);
        }
    }
    updateDb = async (copy) =>{
        let response = await fetch(`/api/projects/${copy.projId}`, {
            headers : {
                "Content-Type": "application/json"
            },
            method: "PATCH",
            body: JSON.stringify({
                type: copy.type
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            console.log(result);
        }
    }
    redirect = (copy) => {
        return this.props.history.push('/build', { username: copy.username, folderPath: copy.folderDest, type: copy.type })
    }

    handleSubmitCopy = e => {
        console.log(
            this.props.location.state.username,
            this.props.location.state.folderPath,
        );
        
        e.preventDefault();
        const copy = {
            username: this.props.location.state.username,
            folderDest: this.props.location.state.folderPath,
            choice: this.state.listChoice
        };
        
        axios
            .post('/api/directory/recursiveCopyExist', copy)
            .then(res => this.props.history.push('/login', { username: copy.username, folderPath: copy.folderDest }))
            .catch(err => 
                this.setState({
                    errors: err.response.data
                })
            );
    };


    componentDidMount() {
        async function getInput(build) {
            return await axios
            .post('/api/directory/getDir', build)
            .then( res => { 
                return res.data;
            })
            .catch( err => console.log(err) );
        };
    
        const build = {
            username: this.props.location.state.username,
        }
        
        getInput(build)
                    .then( res => { 
                        this.setState({
                            list: res
                        })
                    })

        
    };

    render() {
        let userId;
        if (!this.props.location.state.id){
            userId = cookies.get("userId");
        } else {
            userId = this.props.location.state.id;
        }
        let folderDest = this.props.location.state.folderPath;
        return (
            <React.Fragment>
                <div className="container">
                    <div className="containerCopy">
                        <div className="mt-5 text-center mb-0">
                            <h2 className="mt-5 text-center mb-0">Selectionnez le template</h2>
                            <p className="text-lead text-center">Ce template sera la base de votre email</p>
                            <form onSubmit={this.handleSubmit}>
                                <div className="radio">
                                    <label>
                                        <input 
                                            type="radio" 
                                            value="cogedim" 
                                            checked={this.state.selectedOption === 'cogedim'} 
                                            onChange={this.handleOptionChange}
                                        />
                                        cogedim
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="radio">
                                    <label>
                                        <input 
                                            type="radio" 
                                            value="default" 
                                            checked={this.state.selectedOption === 'default'} 
                                            onChange={this.handleOptionChange}
                                        />
                                        default
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <input
                                        type="submit"
                                        className="btn btn-primary justify-content-center d-flex w-50 createButton"
                                        value="Valider"
                                    />
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Copy