import React, { Component } from 'react';

class BadGateway extends Component {

    handleSubmit = () => {
        return this.props.history.push('/')
    }
    render() {
        return (
            <React.Fragment>
                <div className="containerLogin">
                    <div className="containerContent">
                        <h1>Emailing Manager</h1>
                        <div className="containerForm">
                            <div className="leftForm">
                                <img src="img/404.jpg"></img>
                            </div>
                            <div className="rightForm">
                                <h1 className="errTitle">Erreur 404</h1>
                                <div className="backDashErr" onClick={this.handleSubmit}>
                                    <img src="img/back.png"></img>
                                    <p>Revenez en lieu sûr</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default BadGateway