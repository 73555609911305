import Cookies from 'universal-cookie';

const { secretKey } = require('./key');
const cookies = new Cookies();

class Access {
    constructor() {
        this.authenticated = false;
    }

    login(cb) {
        if(secretKey === cb) {
            console.log('connection réussie');
            this.authenticated = true;
            cookies.set("isAuthenticated", true)
            return true
        } else {
            console.log('echec');
            this.authenticated = true;
            return false
            
        }
    }
    logall() {
        this.authenticated = true;
        cookies.set("isAuthenticated", true)
        return true
    }

    logout() {
        return this.authenticated = false;
    }

    isAuthenticated() {
        return this.authenticated;
    }
}

export default new Access()