import React, { Component } from 'react';
import access from './Access';

class AccessRegister extends Component {
    state = {
        code: '',
        displayError: 'none'
    }
    
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            displayError: 'none'
        });
    };

    access = () => {
        var pwd = this.state.code;
        if(access.login((pwd)) === true) {
            return this.props.history.push("/register")
        } else {
            this.setState({
                displayError: 'block'
            })
        }
    }

    render() {
        return (
            <div className="containerLogin">
                    <div className="containerContent">
                        <h1>Emailing Manager</h1>
                        <div className="containerForm">
                            <div className="leftForm">
                                <img src="img/imgLogin.png"></img>
                            </div>
                            <div className="rightForm">
                                <form onSubmit={this.access}>
                                    <h2 className="titleAccessRegister">Veuillez entrer le mot de passe</h2>
                                    <div className="containerChamps">
                                    <div className="">
                                        <input 
                                            className="inputAccessRegister"
                                            type="password"
                                            name="code"
                                            value={this.state.code}
                                            onChange={this.handleChange}
                                            placeholder="Enter code"
                                        />
                                        <span style={{display:this.state.displayError}}>Please make sure to enter the correct password</span>
                                    </div>
                                        
                                    </div>
                                    
                                    <input
                                        type="submit"
                                        className="btn btn-primary justify-content-center d-flex w-50 connexionButton"
                                        value="Connexion"
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default AccessRegister