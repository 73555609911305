import React, { Component } from 'react';
import SearchInput, {createFilter} from 'react-search-input';
import Cookies from 'universal-cookie';
import Loader from 'react-loader-spinner';

import LabelDash from './components/LabelDash';
import Projet from './Project';

const cookies = new Cookies();
const KEYS_TO_FILTERS = ['promoteur', 'campagne', 'ville']
const Loadering = () => <div className="containerLoading loadingDashboard">
                            <div
                                    className="loading"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                <Loader type="ThreeDots" color="#2BAD60" />
                            </div>
                        </div>;
class Projects extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            searchData: [],
            searchDataLength: 0,
            paginateData: [],
            paginateDataLength: 0,
            displaEmptyProj: "none",
            displayProject: "block",
            displaySearch: "none",
            searchTerm: '',
            ongletActif: "encours",
            displayAll: "block"
        }
    }
    /** Récupération des données au chargement de la page */
    async componentDidMount(){
        this.setState({
            isLoading: true
        })

        let paginate = await fetch(`/api/projects/paginated/status/${this.props.selectedType}/user/${this.props.selectedUser}/page/${this.props.page}`);
        let resultPaginate = await paginate.json();
        console.log(resultPaginate.data.projects);
        this.setState({
            paginateData: resultPaginate.data.projects,
            paginateDataLength: resultPaginate.data.projects.length,
            isLoading: false
        },() => {
            if(this.state.paginateDataLength == 0) {
                this.setState({
                    displaEmptyProj: "flex"
                })
            }
        })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.page !== this.props.page) {
          this.getProjects();
        }
        if (prevProps.selectedType !== this.props.selectedType) {
            this.getProjects();
        }
        if (prevProps.userChoix !== this.props.userChoix) {
            this.getProjects();
        }
        /*if(this.state.searchTerm !== '') {
            if (prevProps.selectedType !== this.props.selectedType) {
                this.searchUpdated();
            }
            if (prevProps.selectedUser !== this.props.selectedUser) {
                this.searchUpdated();
            }
        }*/
    }
    async getProjects() {
        this.setState({
            isLoading: true
        })

        let paginate = await fetch(`/api/projects/paginated/status/${this.props.selectedType}/user/${this.props.selectedUser}/page/${this.props.page}`);
        let resultPaginate = await paginate.json();
        console.log(resultPaginate.data.projects);
        this.setState({
            paginateData: resultPaginate.data.projects,
            paginateDataLength: resultPaginate.data.projects.length,
            isLoading: false
        },() => {
            if(this.state.paginateDataLength == 0) {
                this.setState({
                    displaEmptyProj: "flex"
                })
            }
        })
    }
    async reloadList(){
        let paginate = await fetch(`/api/projects/paginated/status/${this.props.selectedType}/user/${this.props.selectedUser}/page/${this.props.page}`);
        let resultPaginate = await paginate.json();

        console.log(resultPaginate.data);

        this.setState({
            paginateData: resultPaginate.data,
            paginateDataLength: resultPaginate.data.length
        })
    }
    /** Gestion de l'affichage des données en fct de l'onglet selectionné */
    ongletEncours = (content) => {
        if(content === true) {
            this.setState({
                ongletActif: "encours"
            })
        }
        if(content === false) {
            this.setState({
                ongletActif: "archive"
            })
        }
    }
    /** Gestion de la recherche */
    async searchUpdated (term) {
        let search = await fetch(`/api/projects/search/status/${this.props.selectedType}/user/${this.props.selectedUser}`);
        let resultSearch = await search.json();
        console.log(resultSearch.data.rows);
        this.setState({
            searchTerm: term,
            searchData: resultSearch.data.rows,
            searchDataLength: resultSearch.data.rows.length
        }, () => {
            if(this.state.searchTerm === '') {
                this.setState({
                    displaySearch: "none",
                    displayAll: 'block'
                })
            }
            if(this.state.searchTerm !== '') {
                this.setState({
                    displaySearch: "block",
                    displayAll: 'none'
                })
            }
        })
    }
    render() {
        let filteredProjects = this.state.searchData.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS));
        let projets = this.state.paginateData.map(elem =>
            <Projet
                key={elem.id}
                id={elem.id}
                status={elem.status} 
                promoteur={elem.promoteur}
                campagne={elem.campagne}
                ville={elem.ville} 
                bat={elem.bat}
                folder={elem.folder}
                createdAt={elem.createdAt} 
                updatedAt={elem.updatedAt}
                type={elem.type}
                userCreator={elem.userCreator}
                userUpdator={elem.userUpdator}
                statusFictif={elem.statusFictif}
                history={this.props.history}
                job={elem.job}
                reloadList={this.reloadList}
            ></Projet>
        );
        let rechercheActuelle = 'containerEmptyDashSearch';
        if(filteredProjects.length == 0) {
            rechercheActuelle = 'containerEmptyDashSearch containerEmptyDashSearchActif';
        } else {
            rechercheActuelle = 'containerEmptyDashSearch';
        }
        return (
                <>
                {(this.state.isLoading) ? <Loadering /> : null}
                <div className="containerProjects">
                    
                    <div>
                        <div className="containerCurrent" style={{ display: this.state.displayAll}}>
                            <div className="containerEmptyDash" style={{ display: this.state.displaEmptyProj}}>
                                <p>Il n'existe aucune conception d'emailing associée.</p>
                            </div>
                            <div className="subcontainerCurrent" style={{ display: this.state.displayProject}}>
                                <LabelDash displayLabel="flex"/>
                                {projets}
                            </div>
                        </div>
                        <div className="containerSearch" style={{ display: this.state.displaySearch}}>
                            <div className={rechercheActuelle}>
                                <p>Il n'existe aucune conception d'emailing associée.</p>
                            </div>
                            <LabelDash/>
                            {filteredProjects.map(elem => {
                                return (
                                    <Projet
                                        key={elem.id}
                                        id={elem.id}
                                        status={elem.status} 
                                        promoteur={elem.promoteur}
                                        campagne={elem.campagne}
                                        ville={elem.ville} 
                                        bat={elem.bat}
                                        folder={elem.folder}
                                        createdAt={elem.createdAt} 
                                        updatedAt={elem.updatedAt}
                                        type={elem.type}
                                        userCreator={elem.userCreator}
                                        userUpdator={elem.userUpdator}
                                        statusFictif={elem.statusFictif}
                                        history={this.props.history}
                                        job={elem.job}
                                        reloadList={this.reloadList}
                                    ></Projet>
                                )
                            })}
                        </div>
                    </div>
                </div>
                </>
        )
    }
}
export default Projects