import React, { Component } from 'react';
import GetImage from './GetImage';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class GetImages extends Component {
    constructor(props){
        super(props);
        this.state = {
            imagesList: [],
            refresh: 0,
            statusProject: ''
        }
    }
    async componentDidMount(){
        let projId = cookies.get("projectId")
        await this.getCaractProj(projId);
        const infos = {
            username: this.props.username,
            folder: this.props.folder
        }
        // console.log(this.state.statusProject);
        
        if(this.state.statusProject === 'Deploiement effectué' || this.state.statusProject === 'Archive' || this.state.statusProject === 'Build effectué' || this.state.statusProject === 'BAT client' || this.state.statusProject === 'BAT interne') {
            await this.getImagesDeploy(infos);
        }
        if(this.state.statusProject === 'En conception' || this.state.statusProject === 'undefined') {
            await this.getImagesDirect(infos);
        }
        // console.log(this.state.refresh);
        
    }
    getCaractProj = async (projId) => {
        let response = await fetch(`/api/projects/${projId}`, {
            headers : {
                "Content-Type": "application/json"
            },
            method: "GET"
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
            this.setState({
                statusProject: result.data.status
            })
        }
    }
    async refreshList(){
        // console.log(this.props.status);
        
        const infos = {
            username: this.props.username,
            folder: this.props.folder
        }
        if(this.props.status === 'Deploiement effectué' || this.props.status === 'Archive' || this.props.status === 'Build effectué' || this.props.status === 'BAT client' || this.props.status === 'BAT interne' || this.props.status === 'En conception') {
            await this.getImagesDeploy(infos);
        }
        if(this.props.status === 'undefined') {
            await this.getImagesDirect(infos);
        }
        // console.log(this.state.refresh);
    }
    getImagesDirect = async (infos) => {
        let response = await fetch("/api/directory/getImagesDirect", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: infos.username,
                folderDest: infos.folder
            })
        })
        
        if (response.status === 200 || response.status === 204){
            // console.log('response ok');
            // console.log(response);
            
            let result = await response.json()
            // console.log(result);
            
            this.setState({
                imagesList: result
            },()=>{
                // console.log(this.state.imagesList);
                
            })
        }
    }
    getImagesDeploy = async (infos) => {
        let response = await fetch("/api/directory/getImagesDeploy", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: infos.username,
                folderDest: infos.folder
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            this.setState({
                imagesList: result
            },()=>{
                console.log(this.state.imagesList);
                
            })
        }
    }


    render() {
        let getImage = this.state.imagesList.map(elem =>
            <GetImage
                key={elem}
                name={elem}
                type={this.props.type}
                username={this.props.username}
                folder={this.props.folder}
            ></GetImage>
        );
        return (
                <div>
                    {getImage}
                </div>
        )
    }
}

export default GetImages