import React, { Component } from 'react';
import {Route, Redirect} from 'react-router-dom';
import access from './Access';

export const ProtectedRoute = ({component: Component, ...rest}) => {
    return(
        <Route {...rest} render={
            (props) => {
                if(access.isAuthenticated()) {
                    return <Component {...props}/>
                }
                else {
                    return <Redirect to={
                     {
                         pathname: "/permissiondenied",
                         state: {
                             from: props.location
                         }
                     }   
                    }/>
                }
                
            }
        }/>
    )
}