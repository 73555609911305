import React, { Component, useRef } from 'react';
import axios from 'axios';
import Partials from './Partials';
import MonacoEditor from 'react-monaco-editor';
import CoolTabs from 'react-cool-tabs';
import Dropzone from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import Cookies from 'universal-cookie';
import Loader from 'react-loader-spinner';
import AceEditor from "react-ace";
import GetImages from './GetImages';
import access from '../auth/Access.js';
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/theme-monokai";
import { log } from 'async';
const dotenv = require('dotenv');
dotenv.config();
const cookies = new Cookies();
const Loadering = () => <div className="containerLoading">
                            <div
                                    className="loading"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                <Loader type="ThreeDots" color="#2BAD60" />
                            </div>
                        </div>;
const LoaderingIsSaving = () => <div className="indicatorIsSaving">
                            <p><nobr>En cours d'enregistrement</nobr></p>
                            <div
                                    className="saving"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <Loader 
                                        type="TailSpin" 
                                        color="#ffaa00"
                                        width="15"
                                        height="15"
                                    />
                            </div>
                        </div>;
class Content extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            content: '',
            css: '',
            errors: {},
            fileValue:'',
            selectedFile: [],
            lien: '',
            statutEnregistré: '',
            displayIsntSaving: 'flex',
            displayIsSaving: 'none',
            displayHtml: "10",
            displayCss: "0",
            activeOngletCss: "transparent",
            activeOngletHtml: "#ff9f2d",
            activeOngletBorderCss: "2px solid #ffffff",
            activeOngletBorderHtml: "none",
            saveColor: "rgba( 255, 255, 255, 0.2)",
            bgSaveColor: "transparent",
            isLoading: false,
            isSaving: false,
            refreshGetmages: 0,
            statusProject: '',
            dbUrl: null,
            imageError: false,
            promoInfo: '',
            jobInfo: '',
            progInfo: '',
            villeInfo: '',
            display: 'block',
            isOpen: true,
            rightWidth: '25vw',
            leftWidth: '75vw',
            posGestion: 'calc(25vw + 10px)',
            rotate: 'rotate(180deg)',
            displaySubURL: 'none',
            ongletIncludeBg: '#33225d',
            ongletIncludeColor: 'rgba( 255, 255, 255, 0.5)',
            ongletImageBg: '#ffaa00',
            ongletImageColor: '#fff',
            ongletImgIncState: 0,
            displayImgContainer: 'flex',
            displayIncludesContainer: 'none',
            selectedPartials: ''
/*
            display: 'none',
            isOpen: false,
            rightWidth: '0',
            leftWidth: '100vw',
            display: 'none',
            posGestion: '0'
*/            
        };
        this.handleEnter = this.handleEnter.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }
    /**
     * 
     */
    singleFileUploadHandler = async (file, remove) => {
        const data = new FormData();
      // If file selected
      console.log(file);
        if ( file ) {
      data.append( 'profileImage', file, file.name );
      axios.post( '/api/amazonStorage/amazonUpload', data, {
          headers: {
           'accept': 'application/json',
           'Accept-Language': 'en-US,en;q=0.8',
           'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
          }
         })
          .then( ( response ) => {
      if ( 200 === response.status ) {
            // If file size is larger than expected.
            if( response.data.error ) {
             if ( 'LIMIT_FILE_SIZE' === response.data.error.code ) {
                alert('Erreur 400 \nFichiers supportés gif, png, jpg, jpeg, svg. Taille < 1Mo')
             } else {
              console.log( response.data );
      // If not the given file type
              alert( response.data.error);
             }
            } else {
             // Success
             let fileName = response.data;
             console.log( 'fileName', fileName );
            }
           }
          }).catch( ( error ) => {
          // If another error
          alert( error );
         });
        } else {
         // if file not selected throw error
         alert( 'Please upload file' );
        }
      };
    /** Gestion sauvegarde clavier */
    handleEnter() {
        this.setState({
            messageTry: this.state.messageTry + 'You pressed the enter key! '
        });
    }
    handleKeyPress = async (e) => {
        if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
            this.setState({
                isSaving: true,
                displayIsntSaving: 'none',
                displayIsSaving: 'flex',
            })
            e.preventDefault();
            const content = {
                css: encodeURI(this.state.css),
                data: encodeURI(this.state.content),
                username: this.props.location.state.userCreator,
                folder: this.props.location.state.folderPath,
                folderDest: this.props.location.state.folderPath,
                projId: cookies.get("projectId"),
                promoteur: this.state.promoInfo,
                job: this.state.jobInfo,
                programme: this.state.progInfo,
                ville: this.state.villeInfo,
                userUpdator: cookies.get("username"),
            };
            await this.writeFile(content);
            await this.writeFileCSS(content);
            await this.replaceUrlLinks(content);
            //await this.createBat(content);
            //await this.prepareBat(content);
            //await this.replaceCssBat(content);
            await this.updateDbControlKey(content);
            // await this.deployBuild(content);
            this.setState({
                displayIsntSaving: 'flex',
                displayIsSaving: 'none',
                isSaving: false,
                statutEnregistré: 'Document sauvegardé',
                saveColor: "rgba( 255, 255, 255, 0.2)",
                bgSaveColor: "transparent"
            })
        }
    }
    updateDbControlKey = async (content) =>{
        // console.log(content);
        let response = await fetch(`/api/projects/${content.projId}`, {
            headers : {
                "Content-Type": "application/json"
            },
            method: "PATCH",
            body: JSON.stringify({
                userUpdator: content.userUpdator,
                promoteur: content.promoteur,
                job: content.job,
                campagne: content.programme,
                ville: content.ville,
                status: "Build effectué",
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });        
    };
    handleChangeDatas = e => {
        this.setState({
            [e.target.name]: e.target.value,
            displaySubURL: 'block'
        });        
    };
    changeDisplay = () => {
        var newDisplay = this.state.display === 'none' ? 'block' : 'none';
        this.setState({ display: newDisplay });
    }
    handleChangeStatus = async ({ meta, file, remove }, status, files) => { 
        if(status !== 'removed'){
            this.setState({
                isLoading: true
            })
        }
        const content = {
            css: encodeURI(this.state.css),
            data: encodeURI(this.state.content),
            username: this.props.location.state.userCreator,
            folder: this.props.location.state.folderPath,
            folderDest: this.props.location.state.folderPath
        };
        let k = 0;
        // console.log(status);
        // console.log('files',files);
        // console.log('file',file);
        /*
        if(status === 'done'){
            // await this.singleFileUploadHandler(file, remove);
            await this.handleSubmitDropOneByOne(file, remove);
            // await this.deployBuild(content);
            this.setState({
                refreshGetmages: this.state.refreshGetmages + 1
            }, () => {
                // console.log(this.state.refreshGetmages);
                this.refs.getImagesFunction.refreshList();
                console.log(this.state.imageError);
                if(this.state.imageError == true){
                    console.log(this.state.imageError);
                    alert('Erreur 400 \nFichiers supportés gif, png, jpg, jpeg, svg. Taille < 1Mo')
                } else {
                    console.log('reload');
                    window.location.reload(true);
                }
                this.setState({
                    isLoading: false,
                    imageError: false
                })
            })
        }*/
        /*for (let i = 0; i < status.length; i++){
            if(status[k].meta.status === 'done'){
                // console.log(k, 'is in');
                
            }
        }*/
        this.state.selectedFile.push(file);
        this.setState({
            isLoading: false,
        })
    }
    handleSubmitDropOneByOne = async (file, remove) => {
        const data = new FormData();
        data.append('myImage', file);
        // console.log(file);
        this.setState({
            imageError: false
        })
        const partOfPath = this.props.location.state.userCreator + '/' + this.props.location.state.folderPath;

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'path': partOfPath
            }
        };

        await axios   
            .post('/api/gulpRoute/uploadImages', data, config)
            .then((response) => {
                //alert("The file is successfully uploaded");
                remove()
            })
            .catch( err => {
                console.log(this.state.imageError);
                this.setState({
                    imageError: true
                })
            });
            
    }
    handleSubmitDrop = async (files, allFiles) => { 
       let arrayFiles = [];
       for (let i = 0; i < files.length; i++) {
           arrayFiles[i] = files[i].file
           // console.log(arrayFiles[i]);
           
       }
        const data = new FormData();
        for (let x = 0; x < arrayFiles.length; x++) {
            data.append('myImage', arrayFiles[x])
        }
        const partOfPath = this.props.location.state.userCreator + '/' + this.props.location.state.folderPath;

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'path': partOfPath
            }
        };

        await axios   
            .post('/api/gulpRoute/uploadImages', data, config)
            .then((response) => {
                // alert("The file is successfully uploaded");
                allFiles.forEach(f => f.remove())
            })
            .catch( err =>
                alert(err.response.data.msg)
            );
        await window.location.reload(true);
        // // console.log(files.map(f => f.meta)) 
    }
    /** Gestion affichage indicateur sauvegarde */
    onChange = e => {
        this.setState({
            content: e,
            statutEnregistré: 'En attente de sauvegarde',
            displayIsntSaving: 'flex',
            displayIsSaving: 'none',
            saveColor: "#ffffff",
            bgSaveColor: "#ffaa00"
        })
        
    }
    onChangeCss = e => {
        this.setState({
            css: e,
            statutEnregistré: 'En attente de sauvegarde',
            displayIsntSaving: 'flex',
            displayIsSaving: 'none',
            saveColor: "#ffffff",
            bgSaveColor: "#ffaa00"
        })
    }
    /** Fonction déploiement, création BAT, ... */
    handleSubmitDeploy = async e => {
        this.setState({
            isLoading: true
        })
        e.preventDefault();
        const content = {
            css: encodeURI(this.state.css),
            data: encodeURI(this.state.content),
            username: this.props.location.state.userCreator,
            folder: this.props.location.state.folderPath,
            type: this.props.location.state.type,
            userUpdator: cookies.get("username"),
            projId: cookies.get("projectId"),
            promoteur: this.state.promoInfo,
            job: this.state.jobInfo,
            programme: this.state.progInfo,
            ville: this.state.villeInfo
        };
        // console.log(content);
        
        await this.writeFile(content);
        await this.writeFileCSS(content);
        await this.cleanBat(content);
        // await this.deployBuild(content);
        // await this.deploy(content);
        await this.createBat(content);
        await this.prepareBat(content);
        await this.replaceCssBat(content);
        await this.updateDbDeploy(content);
        await this.deployMiror(content);
        this.setState({
            isLoading: false
        })
        this.redirect(content);
    }
    deployMiror = async (content) => {
        // console.log(content);
        let response = await fetch("/api/gulpRoute/createMirorLink", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.username,
                promoteur: content.promoteur,
                name: content.programme
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    actualizePreview = async e => {
        this.setState({
            isSaving: true,
            displayIsntSaving: 'none',
            displayIsSaving: 'flex',
        })
        e.preventDefault();
        const content = {
            css: encodeURI(this.state.css),
            data: encodeURI(this.state.content),
            username: this.props.location.state.userCreator,
            folder: this.props.location.state.folderPath,
            folderDest: this.props.location.state.folderPath,
            userUpdator: cookies.get("username"),
            projId: cookies.get("projectId"),
            promoteur: this.state.promoInfo,
            job: this.state.jobInfo,
            programme: this.state.progInfo,
            ville: this.state.villeInfo
        };
        await this.writeFile(content);
        await this.writeFileCSS(content);
        await this.replaceUrlLinks(content);
        // await this.deployBuild(content);
        await this.updateDbSave(content);
        this.setState({
            statutEnregistré: 'Document sauvegardé',
            displayIsntSaving: 'flex',
            displayIsSaving: 'none',
            isSaving: false,
            saveColor: "rgba( 255, 255, 255, 0.2)",
            bgSaveColor: "transparent"
        })
    }
    updateDbSave = async (content) =>{
        // console.log(content);
        let response = await fetch(`/api/projects/${content.projId}`, {
            headers : {
                "Content-Type": "application/json"
            },
            method: "PATCH",
            body: JSON.stringify({
                userUpdator: content.userUpdator,
                promoteur: content.promoteur,
                job: content.job,
                campagne: content.programme,
                ville: content.ville
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    handleSubmitSave = async e => {
        this.setState({
            isSaving: true,
            displayIsntSaving: 'none',
            displayIsSaving: 'flex',
        })
        e.preventDefault();
        const content = {
            css: encodeURI(this.state.css),
            data: encodeURI(this.state.content),
            username: this.props.location.state.userCreator,
            userUpdator: cookies.get("username"),
            folder: this.props.location.state.folderPath,
            type: this.props.location.state.type,
            projId: cookies.get("projectId")
        };
        await this.writeFile(content);
        await this.writeFileCSS(content);
        // await this.deployBuild(content);
        await this.updateDbSave(content);
        this.setState({
            displayIsntSaving: 'flex',
            displayIsSaving: 'none',
            isSaving: false
        })
        // this.redirectSave(content);

    }
    handleSubmit = async e => {
        /*
        this.setState({
            isLoading: true
        })
        e.preventDefault();
        const content = {
            css: encodeURI(this.state.css),
            data: encodeURI(this.state.content),
            username: this.props.location.state.userCreator,
            userUpdator: cookies.get("username"),
            folder: this.props.location.state.folderPath,
            type: this.props.location.state.type,
            projId: cookies.get("projectId")
        };
        await this.writeFile(content);
        await this.writeFileCSS(content);
        await this.cleanBat(content);
        // await this.deployBuild(content);
        await this.updateDbSave(content);
        this.setState({
            isLoading: false
        })
        */
        this.redirectSave();

    }
    writeFile = async (content) => {
        // console.log(content);
        
        let response = await fetch("/api/directory/writefileFinish", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.username,
                data: content.data
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
        return;
    }
    writeFileCSS = async (content) => {
        // console.log(content);
        let response = await fetch("/api/directory/writefileCss", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.username,
                css: content.css
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    cleanBat = async (content) => {
        // console.log(content);
        let response = await fetch("/api/gulpRoute/cleanImgBat", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.username,
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    deployBuild = async (content) => {
        // console.log(content);
        let response = await fetch("/api/gulpRoute/deployBuildOnFtp", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.username,
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    deploy = async (content) => {
        // console.log(content);
        let response = await fetch("/api/gulpRoute/deployOnFtp", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.username,
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    createBat = async (content) => {
        // console.log(content);
        let response = await fetch("/api/gulpRoute/createBat", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.username,
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
    }
    prepareBat = async (content) => {
        // console.log(content);
        let response = await fetch("/api/gulpRoute/prepareBat", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.username,
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
    }
    replaceCssBat = async (content) => {
        // console.log(content);
        let response = await fetch("/api/gulpRoute/replaceCssBat", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.username,
                data: content.data,
                css: content.css,
                promoteur: content.promoteur,
                name: content.programme
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
    }
    updateDbDeploy = async (content) =>{
        // console.log(content);
        let response = await fetch(`/api/projects/${content.projId}`, {
            headers : {
                "Content-Type": "application/json"
            },
            method: "PATCH",
            body: JSON.stringify({
                status: "Deploiement effectué",
                userUpdator: content.userUpdator,
                promoteur: content.promoteur,
                job: content.job,
                campagne: content.programme,
                ville: content.ville
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    redirectSave = () => {
        return this.props.history.push('/dashboard')
    }
    redirect = (content) => {
        return this.props.history.push('/test', { userCreator: content.username, folderPath: content.folder })
    }
    beautifyHtml = async e => {
        await this.handleSubmitSave(e);
        await this.beautify();
        window.location.reload(true);
    }
    beautify = async (content) =>{
        let response = await fetch("/api/gulpRoute/beautify", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: this.props.location.state.userCreator,
                folder: this.props.location.state.folderPath
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    submitChangeDatas = async e => {
        e.preventDefault();
        const content = {
            username: this.props.location.state.userCreator,
            folder: this.props.location.state.folderPath,
            projId: cookies.get("projectId"),
            lien: this.state.lien
        };  
        await this.changeLiens(content);
        await this.updateDbLien(content);
        window.location.reload(true);
    }
    updateDbLien = async (content) =>{
        let response = await fetch(`/api/projects/${content.projId}`, {
            headers : {
                "Content-Type": "application/json"
            },
            method: "PATCH",
            body: JSON.stringify({
                url: content.lien
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    changeLiens = async (content) => {
        let response = await fetch("/api/gulpRoute/changeDataDuplicate",
        {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: content.username,
                folderDest: content.folder,
                lien: content.lien
            })
        }
        );
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    /**
     * 
     */
    includeTemp = async e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const content = {
            css: encodeURI(this.state.css),
            data: encodeURI(this.state.content),
            username: this.props.location.state.userCreator,
            folder: this.props.location.state.folderPath,
            folderDest: this.props.location.state.folderPath,
            projId: cookies.get("projectId"),
            promoteur: this.state.promoInfo,
            job: this.state.jobInfo,
            programme: this.state.progInfo,
            ville: this.state.villeInfo,
            userUpdator: cookies.get("username"),
            type: this.state.selectedPartials
        };
        await this.writeFile(content);
        await this.writeFileCSS(content);
        await this.replaceUrlLinks(content);
        await this.createBat(content);
        await this.prepareBat(content);
        await this.replaceCssBat(content);
        await this.updateDbControlKey(content);
        await this.buildHTML(content);
        await this.cleanInsert(content);
        await this.beautify();
        this.setState({
            isLoading: false
        })
        window.location.reload(true);
    }
    cleanInsert = async (content) =>{
        let response = await fetch("/api/gulpRoute/cleanImg", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                type: content.type,
                username: content.username,
                folder: content.folder
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    buildHTML = async (content) =>{
        console.log(content.type, content.folder, content.username);
        let response = await fetch("/api/gulpRoute/buildHtmlContent", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                type: content.type,
                username: content.username
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            console.log(result);
        }
    }
    /** Ecouteur sauvegarde clavier */
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }
    /** Au chargement */
    componentDidMount = async () => {
        if ( localStorage.getItem('usertoken') == null ) {
            return this.props.history.push('/')
        } else {   
            let objCookies = cookies.getAll();
            let allCookies = Object.keys(objCookies);
            let responseGet = await fetch(`/api/projects/${cookies.get("projectId")}`);
            let resultGet = await responseGet.json();
            console.log(resultGet);
            const build = {
                username: this.props.location.state.userCreator,
                folderDest: this.props.location.state.folderPath,
                type: this.props.location.state.type,
                projId: cookies.get("projectId")
            }
            await this.replaceUrlLinks(build);
            document.addEventListener('keydown', this.handleKeyPress);
            async function getInput(build) {
                return await axios
                .post('/api/directory/readfileFinish', build)
                .then( res => { 
                    return res.data;
                })
                .catch( err =>  console.log(err) );
            };
            async  function getContent(build) {
                try {
                    const resp = await getInput(build);
                    return resp;
                } catch(err) {
                     console.log(err);
                }
            };
            async function getInputCSS(build) {
                return await axios
                .post('/api/directory/readfileCss', build)
                .then( res => { 
                    return res.data;
                })
                .catch( err =>  console.log(err) );
            };
            async  function getContentCSS(build) {
                try {
                    const resp = await getInputCSS(build);
                    
                    return resp;
                } catch(err) {
                     console.log(err);
                }
            };
            getContent(build).then( res => { 
                            this.setState({
                                content: res
                            })
                        })
            getContentCSS(build).then( res => { 
                            this.setState({
                                css: res
                            })
                        })
            
            this.setState({
                dbUrl: resultGet.data.url,
                promoInfo: this.props.location.state.promoteur,
                jobInfo: this.props.location.state.jobNumber,
                progInfo: this.props.location.state.programme,
                villeInfo: this.props.location.state.ville,
                selectedPartials: cookies.get("type")
            })
        }
    }
    openRendu = () => {
        const infos = {
            username: this.props.location.state.userCreator,
            folderDest: this.props.location.state.folderPath,
            type: this.props.location.state.type,
            projId: cookies.get("projectId")
        }
        this.showLocalHtml(infos);
    }
    showLocalHtml = async (build) => {
        console.log('this folderDest' , build.folderDest);
        //let response = await fetch(`http://localhost:5000/api/server/username/${build.username}/folder/${build.folderDest}`);
        await window.open(`https://www.emailingmanager-360.net/api/server/html/${build.username}/${build.folderDest}`, "_blank");
    }
    /** Ouverture fenêtre visualisation de mail */
    showHtmlView = async (build) => {
        await window.open(`http://www.3cent60.biz/emailing/bat/emailingmanager/${build.username}/${build.folderDest}/_build/index.html`, "_blank");
        // await window.open(`http://emailingmanager-360.net/:5000/api/directory/getURL/user/${build.username}/project/${build.folderDest}/html/index.html`, "_blank");
    }
    replaceUrlLinks = async (build) => {
        let response = await fetch('/api/gulpRoute/replacePreview', {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: build.username,
                folder: build.folderDest
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    /**
     * DropZone
     */
    Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
        const text = files.length > 0 ? 'Ajouter des images' : 'Ajouter des images'
      
        return (
          <label style={{ backgroundColor: '#007bff', color: '#fff', cursor: 'pointer', padding: 15, borderRadius: 3 }}>
            {text}
            <input
              style={{ display: 'none' }}
              type="file"
              accept={accept}
              multiple
              onChange={e => {
                getFilesFromEvent(e).then(chosenFiles => {
                  onFiles(chosenFiles)
                })
              }}
            />
          </label>
        )
    }
    getFilesFromEvent = e => {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e).then(chosenFiles => {
                resolve(chosenFiles.map(f => f.fileObject))
            })
        })
    }
    handleSubmitTry = (files, allFiles) => {
        // console.log(files.map(f => f.meta))
        allFiles.forEach(f => f.remove())
    }
/** Affichage du code dans l'éditeur */
    showHtml = () => {
        if(this.state.displayHtml === "0") {
            this.setState({
                displayHtml: "10",
                displayCss: "0",
                activeOngletCss: "transparent",
                activeOngletHtml: "#ff9f2d",
                activeOngletBorderHtml: "none",
                activeOngletBorderCss: "2px solid #ffffff"
            })
        }
    }
    showCss = () => {
        if(this.state.displayCss === "0") {
            this.setState({
                displayHtml: "0",
                displayCss: "10",
                activeOngletCss: "#ff9f2d",
                activeOngletHtml: "transparent",
                activeOngletBorderHtml: "2px solid #ffffff",
                activeOngletBorderCss: "none"
            })
        }
    }
    showIncludeContainer = () => {
        if(this.state.ongletImgIncState === 0) {
            this.setState({
                ongletImgIncState: 1,
                ongletIncludeBg: '#ffaa00',
                ongletIncludeColor: '#fff',
                ongletImageBg: '#33225d',
                ongletImageColor: 'rgba( 255, 255, 255, 0.5)',
                displayImgContainer: 'none',
                displayIncludesContainer: 'flex'
            })
        }
    }
    showImgContainer = () => {
        if(this.state.ongletImgIncState === 1) {
            this.setState({
                ongletImgIncState: 0,
                ongletIncludeBg: '#33225d',
                ongletIncludeColor: 'rgba( 255, 255, 255, 0.5)',
                ongletImageBg: '#ffaa00',
                ongletImageColor: '#fff',
                displayImgContainer: 'flex',
                displayIncludesContainer: 'none'
            })
        }
    }
    gestionFenetre = () => {
        if(this.state.isOpen == true) {
            this.setState({
                isOpen: false,
                rightWidth: '0',
                leftWidth: '100vw',
                display: 'none',
                posGestion: '10px',
                rotate: 'rotate(0deg)'
            }, () => {
                this.refs.reactAceComponent.editor.resize()
            })

        } else {
            this.setState({
                isOpen: true,
                rightWidth: '25vw',
                leftWidth: '75vw',
                display: 'block',
                posGestion: 'calc(25vw + 10px)',
                rotate: 'rotate(180deg)'
            }, () => {
                this.refs.reactAceComponent.editor.resize()
            })
        }
    }
    render() {
        const input = this.state.content;
        const style = this.state.css;
        const { lien, statutEnregistré } = this.state;
        
        return (
            <>
                {/*<div className="absoluteBackDash" onClick={this.handleSubmitSave}></div>*/}
                <div className="containerContent">
                    <div className="containerLeftContent" style={{ width: this.state.leftWidth}}>
                        <div className="onglet">
                                <h3 onClick={this.showHtml} style={{ backgroundColor: this.state.activeOngletHtml, border: this.state.activeOngletBorderHtml}}>HTML</h3>
                                <h3 onClick={this.showCss} style={{ backgroundColor: this.state.activeOngletCss, border: this.state.activeOngletBorderCss}} className="ongletSeparate">CSS</h3>
                                <h3 onClick={this.openRendu} className="ongletRendu">Visualiser</h3>
                                <h3 onClick={this.beautifyHtml} className="ongletRendu">Beautify</h3>
                                <div className="saveOnglet" style={{ display: this.state.displayIsntSaving}}>
                                    <p style={{color: this.state.saveColor}}>{statutEnregistré}</p>
                                    <span style={{ backgroundColor: this.state.bgSaveColor}}></span>
                                </div>   
                                <div className="saveOnglet" style={{ display: this.state.displayIsSaving}}>
                                    {(this.state.isSaving) ? <LoaderingIsSaving /> : null}
                                </div>          
                        </div>
                        <div className="containerMonaco" style={{ zIndex: this.state.displayHtml}}>
                            <AceEditor
                                placeholder="Placeholder Text"
                                mode="html"
                                theme="monokai"
                                name="buildHtml"
                                onChange={this.onChange}
                                fontSize={14}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                value={input}
                                ref="reactAceComponent"
                                setOptions={{
                                    enableBasicAutocompletion: true,
                                    enableLiveAutocompletion: true,
                                    enableSnippets: true,
                                    showLineNumbers: true,
                                    tabSize: 2,
                                    wrap: true,
                                    printMarginColumn: 80
                                }}
                            />
                        </div>
                        <div className="containerMonaco" style={{ zIndex: this.state.displayCss}}>
                            <AceEditor
                                placeholder="Placeholder Text"
                                mode="css"
                                theme="monokai"
                                name="buildCss"
                                onChange={this.onChangeCss}
                                fontSize={14}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                value={style}
                                setOptions={{
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                enableSnippets: true,
                                showLineNumbers: true,
                                tabSize: 4,
                                wrap: true,
                                }}
                            />
                        </div>
                        
                    </div>
                    <div className="bandeauGestion" style={{ right: this.state.posGestion}}>
                        <div onClick={this.gestionFenetre} className="gestionRightContent">
                            {this.state.isOpen ? <div className="containerGestionFenetre"><img style={{ transform: this.state.rotate}} src="img/openArrow.png"></img></div> : <div style={{ transform: this.state.rotate}} className="containerGestionFenetre"><img src="img/openArrow.png"></img></div> }
                        </div>
                    </div>
                    
                    <div className="containerRightContent" style={{ width: this.state.rightWidth}}>
                        {(this.state.isLoading) ? <Loadering /> : null}
                        
                        <div className="buildInfos">
                            <input 
                                type="text"
                                name="promoInfo"
                                value={this.state.promoInfo}
                                onChange={this.handleChange}
                            />
                            <div className="buildInfosProg">
                                <div className="infoJob">
                                    <h2>#</h2>
                                    <input 
                                        type="text"
                                        name="jobInfo"
                                        value={this.state.jobInfo}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <input 
                                    type="text"
                                    name="progInfo"
                                    value={this.state.progInfo}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="infoBuildVille">
                                <img src="img/ic-infoVille.png"></img>
                                <input 
                                    type="text"
                                    name="villeInfo"
                                    value={this.state.villeInfo}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="ongletImgBloc">
                            <div className="item">
                                <p onClick={this.showImgContainer} style={{ backgroundColor: this.state.ongletImageBg, color: this.state.ongletImageColor}}>Images</p>
                            </div>
                            <div className="item">
                                <p onClick={this.showIncludeContainer} style={{ backgroundColor: this.state.ongletIncludeBg, color: this.state.ongletIncludeColor}}>Includes</p>
                            </div>
                        </div>
                        <div className="containerIncludes" style={{ display: this.state.displayIncludesContainer}}>
                            <form className="changeLinks" onSubmit={this.submitChangeDatas}>
                                <div className="input">
                                    <input 
                                        className="champsForm"
                                        type="text"
                                        name="lien"
                                        value={lien}
                                        onChange={this.handleChangeDatas}
                                        placeholder="URL par défaut"
                                    />
                                    <input
                                        id="insererURL"
                                        type="submit"
                                        className="btn btn-primary justify-content-center createButton"
                                        value=""
                                        style={{ display: this.state.displaySubURL }}
                                    />
                                </div>
                            </form>
                            <h4>Sections à inclure</h4>
                            <div className="relative">
                                <div className="containerPartials">
                                    <Partials modele={this.state.selectedPartials}></Partials>
                                </div>
                                <button onClick={this.includeTemp}>Insérer</button>
                            </div>
                        </div>
                        <div className="contentFormLien" style={{ display: this.state.displayImgContainer}}>
                            {this.state.dbUrl === null ? 
                            <>
                                
                                <div className="containerUploadContent">
                                    <h3>images du projet</h3>
                                    <div className="uploadImage">
                                        <div className="relative">
                                            <div className="empty">
                                                <GetImages ref="getImagesFunction" status={this.state.statusProject} username={this.props.location.state.userCreator} folder={this.props.location.state.folderPath} type="default" refresh={this.state.refreshGetmages}></GetImages>
                                            </div>
                                        </div>
                                        
                                        <div 
                                            className="row"
                                            style={{display:this.state.display}}
                                            
                                        >
                                            <Dropzone
                                                getUploadParams={() => ({ url: 'https://httpbin.org/post' })}
                                                InputComponent={this.Input}
                                                getFilesFromEvent={this.getFilesFromEvent}
                                                onChangeStatus={this.handleChangeStatus}
                                                onSubmit={this.handleSubmitDrop}
                                                accept="image/*"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <> 
                                <div className="containerUploadContent">
                                    <h3>images du projet</h3>
                                    <div className="uploadImage">
                                        <div className="relative">
                                            <div className="empty" style={{height: 'calc(100vh - 415px)'}}>
                                                <GetImages ref="getImagesFunction" status={this.state.statusProject} username={this.props.location.state.userCreator} folder={this.props.location.state.folderPath} type="default" refresh={this.state.refreshGetmages}></GetImages>
                                            </div>
                                        </div>
                                        
                                        <div 
                                            className="row"
                                            style={{display:this.state.display}}
                                            
                                        >
                                            <Dropzone
                                                getUploadParams={() => ({ url: 'https://httpbin.org/post' })}
                                                InputComponent={this.Input}
                                                getFilesFromEvent={this.getFilesFromEvent}
                                                onChangeStatus={this.handleChangeStatus}
                                                onSubmit={this.handleSubmitDrop}
                                                accept="image/*"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                            }
                        </div>
                        <div className="contentButtons">
                            {/*<form className="saveTry" onSubmit={this.actualizePreview}>
                                <img src="img/ic-save.png"></img>
                                <input
                                    type="submit"
                                    className="btn btn-primary justify-content-center d-flexcreateButton"
                                    value="Enregistrer pour tester"
                                />
                            </form>*/}
                            <div className="footerContent">
                                <div className="backDash" onClick={this.handleSubmit}>
                                    <img src="img/backWhite.png"></img>
                                    <p>Dashboard</p>
                                </div>
                                <form className="send" onSubmit={this.handleSubmitDeploy}>
                                    <input
                                        type="submit"
                                        className="btn btn-primary justify-content-center d-flex createButton"
                                        value="Envoyer"
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Content