import React, { Component } from 'react';
// import AceEditor from "react-ace";
import Partials from './Partials';
import axios from 'axios';
import MonacoEditor from 'react-monaco-editor';
import CoolTabs from 'react-cool-tabs';
import Editor from '@monaco-editor/react';
import Cookies from 'universal-cookie';
import Loader from 'react-loader-spinner';
import AceEditor from "react-ace";
import access from '../auth/Access.js'
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/theme-monokai";
const cookies = new Cookies();
const Loadering = () => <div className="containerLoading">
                            <div
                                    className="loading"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                <Loader type="ThreeDots" color="#2BAD60" />
                            </div>
                        </div>;
class Build extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            content: '',
            css: '',
            preheader: '',
            titre: '',
            lien: '',
            partialsList: [],
            displayHtml: "10",
            displayCss: "0",
            activeOngletCss: "transparent",
            activeOngletHtml: "#ff9f2d",
            activeOngletBorderCss: "2px solid #ffffff",
            activeOngletBorderHtml: "none",
            isLoading: false,
            selectedPartials: "",
            imgSelected: "img/ic-default.png",
            statutEnregistré: '',
            saveColor: "rgba( 255, 255, 255, 0.2)",
            bgSaveColor: "transparent",
            timerShape: 0,
            refTimerShape: 0,
            activationShape: 0,
            promoInfo: '',
            jobInfo: '',
            progInfo: '',
            villeInfo: ''
        };
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.interval = setInterval(this.functionShape, 20);
    }
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });        
    };
    /** Get html content on change */
    onChange = e => {
        this.setState({
            content: e,
            statutEnregistré: 'En attente de sauvegarde',
            saveColor: "#ffffff",
            bgSaveColor: "#ffaa00"
        })
    }
    editorDidMount(editor, monaco) {
       // console.log('editorDidMount', editor);
        editor.focus();
    }
    /** Get css content on change */
    onChangeCss = e => {
        this.setState({
            css: e,
            statutEnregistré: 'En attente de sauvegarde',
            saveColor: "#ffffff",
            bgSaveColor: "#ffaa00"
        })
    }
    editorDidMountCss(editor, monaco) {
        editor.focus();
      }
    save = async e => {
        this.setState({
            isLoading: true
        })
        e.preventDefault();
        const content = {
            css: encodeURI(this.state.css),
            data: encodeURI(this.state.content),
            userCreator: this.props.location.state.userCreator,
            userUpdator: cookies.get("username"),
            folder: this.props.location.state.folderPath,
            type: this.props.location.state.type,
            preheader: this.state.preheader,
            projId: cookies.get("projectId"),
            title: this.state.titre,
            lien: this.state.lien
            
        };  

        await this.writeCssTpl(content);
        await this.writeHtmlTpl(content);
        this.setState({
            isLoading: false
        })
        this.redirectDash(content);
    }
    redirectDash = (content) => {
        return this.props.history.push('/dashboard', { userCreator: content.userCreator, folderPath: content.folder, status: this.props.location.state.status,  promoteur: this.props.location.state.promoteur, jobNumber: this.props.location.state.jobNumber, programme: this.props.location.state.programme, ville: this.props.location.state.ville})
    }
    writeHtmlTpl = async (content) =>{
        let response = await fetch("/api/directory/writefile", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.userCreator,
                data: content.data
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
        return;
    }
    writeCssTpl = async (content) =>{
        let response = await fetch("/api/directory/writefileCss", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.userCreator,
                css: content.css
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    handleSubmit = async e => {
        this.setState({
            isLoading: true
        })
        e.preventDefault();
        const content = {
            css: encodeURI(this.state.css),
            data: encodeURI(this.state.content),
            userCreator: this.props.location.state.userCreator,
            userUpdator: cookies.get("username"),
            folder: this.props.location.state.folderPath,
            type: this.props.location.state.type,
            preheader: this.state.preheader,
            projId: cookies.get("projectId"),
            title: this.state.titre,
            lien: this.state.lien,
            promoteur: this.state.promoInfo,
            job: this.state.jobInfo,
            programme: this.state.progInfo,
            ville: this.state.villeInfo
            
        };  
        await this.checkData(content);      
        await this.writeFile(content);
        await this.writeFileCSS(content);
        await this.buildHTML(content);
        await this.insertData(content);
        await this.beautify(content);
        await this.beautify(content);
        await this.clean(content);
        await this.deploy(content);
        await this.updateDb(content);
        this.setState({
            isLoading: false
        })
        this.redirect(content);
    }
    /** Gestion modification du contenu des liens, preheader, ... */
    checkData = async (content) => {
        if(content.preheader === '') {
            this.setState({
                preheader: content.preheaderBDD
            }, () => {
                content.preheader = content.preheaderBDD
            })
        }
        if(content.title === '') {
            this.setState({
                title: content.promoteur
            }, () => {
                content.title = content.promoteur
                // console.log(this.state.title);
            })
        }
        if(content.lien === '') {
            this.setState({
                lien: content.lienBDD
            }, () => {
                content.lien = content.lienBDD
                // console.log(this.state.lien);
            })
        }
    }
    writeFile = async (content) =>{
        let response = await fetch("/api/directory/writefile", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.userCreator,
                data: content.data
            })
        })
        
        if (response.status === 200 || response.status === 204){
            
            let result = await response.json()
            // console.log(result);
        }
        return;
    }
    writeFileCSS = async (content) =>{
        let response = await fetch("/api/directory/writefileCss", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.userCreator,
                css: content.css
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    buildHTML = async (content) =>{
        let response = await fetch("/api/gulpRoute/buildHtml", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                type: content.type,
                username: content.userCreator
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    beautifyCSS = async (content) =>{
        let response = await fetch("/api/gulpRoute/beautifyCSS", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: content.userCreator,
                folder: content.folder
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    beautify = async (content) =>{
        let response = await fetch("/api/gulpRoute/beautify", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                username: content.userCreator,
                folder: content.folder
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    insertData = async (content) =>{
        let response = await fetch("/api/gulpRoute/insertDatas", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                title: content.title,
                preheader: content.preheader,
                username: content.userCreator,
                folder: content.folder,
                lien: content.lien
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    clean = async (content) =>{
        let response = await fetch("/api/gulpRoute/cleanImg", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                type: content.type,
                username: content.userCreator,
                folder: content.folder
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    deploy = async (content) => {
        // console.log(content);
        let response = await fetch("/api/gulpRoute/deployBuildOnFtp", {
            headers : {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                folder: content.folder,
                username: content.userCreator,
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            // console.log(result);
        }
    }
    updateDb = async (content) =>{
        let response = await fetch(`/api/projects/${content.projId}`, {
            headers : {
                "Content-Type": "application/json"
            },
            method: "PATCH",
            body: JSON.stringify({
                status: "Build effectué",
                url: content.lien,
                userUpdator: content.userUpdator,
                promoteur: content.promoteur,
                job: content.job,
                campagne: content.programme,
                ville: content.ville
            })
        })
        
        if (response.status === 200 || response.status === 204){
            let result = await response.json()
            console.log(result);
            console.log(content);
        }
    }
    redirect = (content) => {
        return this.props.history.push('/content', { urlChangee: this.state.urlChangee, userCreator: content.userCreator, folderPath: content.folder, status: this.props.location.state.status,  promoteur: this.props.location.state.promoteur, jobNumber: this.props.location.state.jobNumber, programme: this.props.location.state.programme, ville: this.props.location.state.ville})
    }
    actionShape = () => {
        this.setState({
            activationShape: 1
        }, () => {
            this.functionShape()
        })
    }
    desactiveShape = () => {
        this.setState({
            activationShape: 0
        }, () => {
            this.functionShape()
        })
    }
    functionShape = () => {
        if(this.state.activationShape === 0) {
            clearInterval(this.timerInterval);
        }
        if(this.state.activationShape === 1) {
            this.setState({ timerShape: this.state.refTimerShape + 1 }
            
                ,()=> {
                    this.setState({
                        refTimerShape: this.state.timerShape
                    })
                   // // console.log('+1');
                    //// console.log(this.state.timerShape);
                   // // console.log(this.state.refTimerShape);
                })
        }
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }
    handleKeyPress = async (e) => {
        if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
            e.preventDefault();
            const content = {
                css: encodeURI(this.state.css),
                data: encodeURI(this.state.content),
                userCreator: this.props.location.state.userCreator,
                userUpdator: cookies.get("username"),
                folder: this.props.location.state.folderPath,
                type: this.props.location.state.type,
                preheader: this.state.preheader,
                projId: cookies.get("projectId"),
                title: this.state.titre,
                lien: this.state.lien
                
            };  

            await this.writeCssTpl(content);
            await this.writeHtmlTpl(content);
            this.setState({
                statutEnregistré: 'Document sauvegardé',
                saveColor: "rgba( 255, 255, 255, 0.2)",
                bgSaveColor: "transparent"
            })
        }
        }
    /** 
     * Au chargement de la page 
     * Récupération du code à afficher dans l'éditeur
     * */
    async componentDidMount() {
        let objCookies = cookies.getAll();
        let allCookies = Object.keys(objCookies);
        async function getInput(build) {
            return await axios
            .post('/api/directory/readfile', build)
            .then( res => { 
                return res.data;
            })
            .catch( err => console.log(err) );
        };
        async  function getContent(build) {
            try {
                const resp = await getInput(build);
               // // console.log('resp', resp);
                
                return resp;
            } catch(err) {
                // console.log(err);
            }
        };
        async function getInputCSS(build) {
            return await axios
            .post('/api/directory/readfileCss', build)
            .then( res => { 
                return res.data;
            })
            .catch( err => console.log(err) );
        };
        async  function getContentCSS(build) {
            try {
                const resp = await getInputCSS(build);
                
                return resp;
            } catch(err) {
                // console.log(err);
            }
        };
        let responseGet = await fetch(`/api/projects/${cookies.get("projectId")}`);
        let resultGet = await responseGet.json();
        const build = {
            username: this.props.location.state.userCreator,
            folderDest: this.props.location.state.folderPath,
            programme: this.props.location.state.programme,
            promoteur: this.props.location.state.promoteur,
            type: resultGet.data.type,
            projId: cookies.get("projectId")
        }
        this.setState({
            selectedPartials: resultGet.data.type,
            promoInfo: this.props.location.state.promoteur,
            jobInfo: this.props.location.state.jobNumber,
            progInfo: this.props.location.state.programme,
            villeInfo: this.props.location.state.ville
        })
        // console.log(build); 
        document.addEventListener('keydown', this.handleKeyPress);
        getContent(build)
                    .then( res => { 
                        // // console.log(res);
                        
                        this.setState({
                            content: res
                        })
                    })
        getContentCSS(build)
                    .then( res => { 
                        // // console.log(res);
                        
                        this.setState({
                            css: res
                        })
                    })
        async function getPartialsList(build) {
            return await axios
            .post('/api/directory/getPartials', build)
            .then( res => { 
                return res.data;
            })
            .catch( err => console.log(err) );
        };
        async  function getPartials(build) {
            try {
                const resp = await getPartialsList(build);
                // console.log(resp);
                // console.log('programme',build.programme);
                // console.log('promoteur',build.promoteur);
                // console.log('type',build.type);
                return resp;
            } catch(err) {
                // console.log(err);
            }
        };
        getPartials(build)
                    .then( res => { 
                        this.setState({
                            partialsList: res
                        })
                    })
    };
    /** Gestion des onglets de l'éditeur */
    showHtml = () => {
        if(this.state.displayHtml === "0") {
            this.setState({
                displayHtml: "10",
                displayCss: "0",
                activeOngletCss: "transparent",
                activeOngletHtml: "#ff9f2d",
                activeOngletBorderHtml: "none",
                activeOngletBorderCss: "2px solid #ffffff"
            })
        }
    }
    showCss = () => {
        if(this.state.displayCss === "0") {
            this.setState({
                displayHtml: "0",
                displayCss: "10",
                activeOngletCss: "#ff9f2d",
                activeOngletHtml: "transparent",
                activeOngletBorderHtml: "2px solid #ffffff",
                activeOngletBorderCss: "none"
            })
        }
    }
    render() {
        const input = this.state.content;
        const style = this.state.css;
        const list = this.state.partialsList;
        const statutEnregistré = this.state.statutEnregistré;
        const { titre, preheader, lien } = this.state;
        let i = 0;
        const listItems = list.map((list) =>
                    <li key={++i} className="text-center"><code>&lt;%include {list}%&gt;.</code></li>
                                    );
        const type = this.props.location.state.type;
        return (
            <>
                {/*<div className="absoluteBackDash" onClick={this.save}></div>*/}
                <div className="containerBuild">
                    {(this.state.isLoading) ? <Loadering /> : null}
                    <div className="containerLeftBuild">
                        <div className="onglet">
                            <h3 onClick={this.showHtml} style={{ backgroundColor: this.state.activeOngletHtml, border: this.state.activeOngletBorderHtml}}>HTML</h3>
                            <h3 onClick={this.showCss} style={{ backgroundColor: this.state.activeOngletCss, border: this.state.activeOngletBorderCss}} className="ongletSeparate">CSS</h3>
                            <div className="saveOnglet">
                                <p style={{color: this.state.saveColor}}>{statutEnregistré}</p>
                                <span style={{ backgroundColor: this.state.bgSaveColor}}></span>
                            </div>
                            
                        </div>
                        <div className="containerMonaco" style={{ zIndex: this.state.displayHtml}}>
                            <AceEditor
                                placeholder="Placeholder Text"
                                mode="html"
                                theme="monokai"
                                name="buildHtml"
                                onChange={this.onChange}
                                fontSize={14}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                value={input}
                                setOptions={{
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                enableSnippets: true,
                                showLineNumbers: true,
                                tabSize: 3,
                                wrap: true,
                                }}
                            />
                            
                        </div>
                        <div className="containerMonaco" style={{ zIndex: this.state.displayCss}}>
                            <AceEditor
                                placeholder="Placeholder Text"
                                mode="css"
                                theme="monokai"
                                name="buildCss"
                                onChange={this.onChangeCss}
                                fontSize={14}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                value={style}
                                setOptions={{
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                enableSnippets: true,
                                showLineNumbers: true,
                                tabSize: 1,
                                wrap: true,
                                }}
                            />
                        </div>
                        
                        
                    </div>
                    <div className="containerRightBuild">
                        <div className="buildInfos">
                            <input 
                                type="text"
                                name="promoInfo"
                                value={this.state.promoInfo}
                                onChange={this.handleChange}
                            />
                            <div className="buildInfosProg">
                                <div className="infoJob">
                                    <h2>#</h2>
                                    <input 
                                        type="text"
                                        name="jobInfo"
                                        value={this.state.jobInfo}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <input 
                                    type="text"
                                    name="progInfo"
                                    value={this.state.progInfo}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="infoBuildVille">
                                <img src="img/ic-infoVille.png"></img>
                                <input 
                                    type="text"
                                    name="villeInfo"
                                    value={this.state.villeInfo}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        
                        <form className="formBuild">
                            <div className="input">
                                <input 
                                    type="text"
                                    name="titre"
                                    value={titre}
                                    onChange={this.handleChange}
                                    placeholder="Title"
                                />
                            </div>
                            <div className="input">
                                <input 
                                    type="text"
                                    name="preheader"
                                    value={preheader}
                                    onChange={this.handleChange}
                                    placeholder="Preheader"
                                />
                            </div>
                            <div className="input">
                                <input 
                                    type="text"
                                    name="lien"
                                    value={lien}
                                    onChange={this.handleChange}
                                    placeholder="URL par défaut"
                                />
                            </div>
                            <h4>Sections à inclure</h4>
                            <div className="relative">
                                <div className="containerPartials">
                                    <Partials modele={this.state.selectedPartials}></Partials>
                                </div>
                            </div>
                            
                            <div className="footerBuild">
                                <div className="backBuild" onClick={this.save}>
                                    <img src="img/backWhite.png"></img>
                                    <p>Dashboard</p>
                                </div>
                                <div className="buildButton" onMouseLeave={this.desactiveShape} onMouseOver={this.actionShape}  onClick={this.handleSubmit}>
                                    <p>Build</p>
                                    <img style={{ transform: 'rotateZ(' + this.state.timerShape + 'deg)'}} src="./img/shape.png"></img>
                                </div>
                            </div>
                            
                        </form>
                    </div>
                    
                    
                </div>
            
            </>
        )
    }
}

export default Build